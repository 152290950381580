import { FC, useEffect, useState } from 'react'
import { useRouteQuery } from '../../hooks/useRouteQuery'
import { LoadingState } from '../../local-core-ui'
import { readProjectIntoStore } from '../../store/projectWizard/thunks'
import { StepContainer } from './components/stepContainer/stepContainer'
import { WizardBackButton } from './components/wizardButtons/wizardBackButton'
import { WizardCompleteSetupButton } from './components/wizardButtons/wizardCompleteSetupButton'
import { WizardNextButton } from './components/wizardButtons/wizardNextButton'
import { WizardStepper } from './components/wizardStepper/wizardStepper'
import { WizardDefinition } from './definitions/wizardDefinition'
import { useWizardContext } from './hooks/useWizardContext'
import styles from './wizardComposer.module.scss'

export interface WizardKey {
	prefix: string
	wizKey: string
	step: string
	substep: string
}

export const extractWizardKey = (path: string): WizardKey | undefined => {
	const tokens = path.split('?')[0].split('/')

	return {
		prefix: tokens[1] || '',
		wizKey: tokens[2] || '',
		step: tokens[3] || '',
		substep: tokens[4] || ''
	} as WizardKey
}

export const WizardComposer: FC = () => {
	const query = useRouteQuery()
	const projectId = query.get('projectId') || undefined
	const sourceId = query.get('sourceId') || undefined
	const { wizardContext } = useWizardContext()
	const [reading, setReading] = useState(false)

	useEffect(() => {
		if (
			!reading &&
			projectId &&
			(wizardContext.projectWizardState.currentProject.id !== projectId ||
				(sourceId && wizardContext.projectWizardState.currentProject.source.id !== sourceId))
		) {
			setReading(true)

			wizardContext
				.dispatch(
					readProjectIntoStore(
						projectId,
						wizardContext.enableApi,
						wizardContext.salesforcePlatform,
						wizardContext.isConnectManage,
						sourceId !== undefined,
						sourceId !== 'first' ? sourceId : undefined
					)
				)
				.finally(() => setReading(false))
		}
		/**
		 * We only want to run this effect when projectId or sourceId props changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, sourceId])

	return (
		<div className={styles.wizardContainer}>
			<WizardStepper routes={WizardDefinition} />
			<div className={styles.wizardContentContainer}>
				<div className={styles.buttonContainer}>
					<WizardBackButton />
				</div>
				{reading ? <LoadingState /> : <StepContainer />}
				<div className={styles.buttonContainer}>
					<WizardNextButton />
				</div>
			</div>
			<WizardCompleteSetupButton />
		</div>
	)
}
