import { DNBAutocomplete, DNBInputLabel } from '@dnb-uux-design-system/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, RadioButton } from '../../local-core-ui'
import styles from './enrichment-rule-setting.module.scss'

export interface DropdownOptions {
	label: string
	value: string
	disabled?: boolean
}

export interface EnrichmentRuleSettingProps {
	id: string
	label: string
	selected?: string
	options: Array<DropdownOptions>
	disabled?: boolean
	alwaysSelected: boolean
	onChangeFunction?: (selected?: string, always?: boolean) => void
	testId: string
}

export const EnrichmentRuleSetting = ({
	id,
	label,
	selected,
	options,
	disabled = false,
	alwaysSelected,
	onChangeFunction,
	testId
}: EnrichmentRuleSettingProps) => {
	const { t } = useTranslation()
	const [selection, setSelection] = useState(selected)
	const [alwaysSelectedSelection, setAlwaysSelectedSelection] = useState(alwaysSelected)

	const onChangeDropDown = (newSelection: DropdownOptions | null) => {
		if (newSelection) {
			setSelection(newSelection.value)
			onChangeFunction && onChangeFunction(newSelection.value, alwaysSelectedSelection)
		} else {
			setSelection('')
			onChangeFunction && onChangeFunction('', alwaysSelectedSelection)
		}
	}

	const onChangeAlwaysSelected = (newAlwaysSelected: boolean) => {
		setAlwaysSelectedSelection(newAlwaysSelected)
		const selectOption = options.find(({ value, label }) => value === selection || label === selection)
		onChangeFunction && onChangeFunction(selectOption?.value, newAlwaysSelected)
	}

	return (
		<div className={styles.dropdownWrapperSpacing}>
			<Grid testId="container-enrichment-rule" container>
				<Grid testId="dropdown-wrapper-enrichment-rule" sm={8}>
					<div className={styles.dropdownWrapper}>
						<div className={styles.dropdownContainer}>
							<DNBInputLabel title={label} size="default">
								{label}
							</DNBInputLabel>
							<DNBAutocomplete
								placeholder={t('enrich.crm.select.matching.column') as string}
								disabled={disabled}
								size="default"
								sx={{ width: 300 }}
								fullWidth={true}
								value={selected}
								options={options.sort((a, b) => -b.label.localeCompare(a.label))}
								getOptionDisabled={(option) => option.disabled ?? false}
								onChange={(_, newSelection) => onChangeDropDown(newSelection as DropdownOptions | null)}
								testId={testId + '-dd-collection'}
							/>
						</div>
					</div>
				</Grid>
				<Grid testId="radio-wrapper-enrichment-rule-first" sm={2}>
					<div className={styles.radioWrapper}>
						<RadioButton
							value={'radio1'}
							group={`${id}-radio-group`}
							id={`${id}-radio-blank`}
							label={t('enrich.crm.when.blank')}
							disabled={disabled}
							checked={!alwaysSelectedSelection}
							onChange={() => onChangeAlwaysSelected(false)}
							testId={`${id}-when-blank`}
						/>
					</div>
				</Grid>
				<Grid testId="radio-wrapper-enrichment-rule-second" sm={2}>
					<div className={styles.radioWrapper}>
						<RadioButton
							value={'radio2'}
							group={`${id}-radio-group`}
							id={`${id}-radio-always`}
							label={t('enrich.crm.always')}
							disabled={disabled}
							checked={alwaysSelectedSelection}
							onChange={() => onChangeAlwaysSelected(true)}
							testId={`${id}-always`}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
