import { DNBButton } from '@dnb-uux-design-system/react'
import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
	ColorBackgroundModalTransparency,
	ColorBlueBrand,
	ColorGray,
	ColorScaleRed,
	ColorWhite
} from '../../../../design-tokens/build/shared/token-colors.json'
import dbconnect from '../../assets/images/dnb-amp-logo.svg'
import { getLocalizedDateString } from '../../helpers'
import { useFeatures, usePlatform } from '../../hooks/useEntitlements'
import { useOrgId } from '../../hooks/useOrgId'
import { Divider, Icon, Modal, Tab, Tabs } from '../../local-core-ui/'
import {
	ConnectionEnvironment,
	ConnectionStatus as StatusType,
	CreateQueryStatus
} from '../../queries/api/getC4SActiveConnection'
import { ConnectionDetail } from '../../queries/api/getConnection'
import { System } from '../../queries/api/getConnections'
import { SuccessResponse } from '../../queries/api/updateConnection'
import { useDeactivateConnection } from '../../queries/useDeactivateConnection'
import { useUpdateConnection } from '../../queries/useUpdateConnection'
import { authenticateAgainst3rdParty, RootState, useAppDispatch, useAppSelector } from '../../store'
import { clearApiErrorAction } from '../../store/apiError'
import { setAuthOrigin } from '../../store/connection/connectionSlice'
import { AuthOrigins } from '../../store/session/types/AuthOrigins'
import { C4SFailConnectionModal } from '../c4s-fail-connection-modal/c4s-fail-connection-modal'
import { ConnectionSources } from '../connection-sources/connection-sources'
import { ConnectionStatus } from '../connection-status/connection-status'
import { EditConnectManageConnection } from '../edit-connect-manage-connection/edit-connect-manage-connection'
import salesforce from '../integration-tile/png/salesforce.png'
import snowflakes from '../integration-tile/png/snowflakes.png'
import { storageEventListener } from '../new-connection-modal/new-connection-modal'
import { SalesforceEnvironment } from '../salesforce-modal/salesforce-modal'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { SnowCloud, SnowCloudRegion, SnowflakeData, SnowflakeForm, SnowRegion } from '../snowflake-form/snowflake-form'
import styles from './edit-connection.module.scss'

interface SnowflakeModalProps {
	onEditedSuccessfully(editedDisplayName: ConnectionDetail): void
	onCancelEdit(): void
	onDelete(): void
	connectionData: ConnectionDetail
	currentConnectionStatus: StatusType
	testId: string
	isConnectionStatusLoading: boolean
	defaultTab?: TabIndexValue
}

export enum TabIndexValue {
	information,
	edit
}

export const EditConnection = ({
	onEditedSuccessfully,
	onCancelEdit,
	onDelete,
	connectionData,
	currentConnectionStatus,
	testId,
	isConnectionStatusLoading,
	defaultTab = TabIndexValue.information
}: SnowflakeModalProps): ReactElement => {
	const { t } = useTranslation()
	const orgId = useOrgId()
	const dispatch = useAppDispatch()
	const updateConnectionMutation = useUpdateConnection()
	const deactivateConnectionMutation = useDeactivateConnection()
	const enableSnowflakeConnection = useFeatures(['EnableSnowflakeConnection'])
	const [snowData, setSnowData] = useState<SnowflakeData>()
	const [message, setMessage] = useState('')
	const [isEdited, setIsEdited] = useState(false)
	const [showCloseModal, setShowCloseModal] = useState(false)
	const [hasErrorResponse, setHasErrorResponse] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { authenticationSummary } = connectionData
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const [currentConnection, setCurrentConnection] = useState<SnowflakeData>({
		connectionType: System.Snowflake,
		username: authenticationSummary?.userName || '',
		organization: authenticationSummary?.organization || '',
		domainName: authenticationSummary?.domainName || '',
		cloud: authenticationSummary?.cloud || SnowCloud.Empty,
		region: authenticationSummary?.region || SnowRegion.Empty,
		cloudRegion: authenticationSummary?.cloudRegion || SnowCloudRegion.Empty,
		nameConnection: connectionData?.displayName || ''
	})
	const [currentTab, setCurrentTab] = useState(defaultTab)
	const isSalesforce = connectionData.system === System.Salesforce || connectionData.system === System.ConnectManage
	const enableManagedConnectedApp = useFeatures(['C4SEnableManagedConnectedApp'])
	const disableC4SOAuth = useFeatures(['DisableC4SOAuth'])
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const [reauthenticateModal, setReauthenticateModal] = useState(false)
	const [failC4SModal, setFailC4SModal] = useState(false)
	const isC4SPlatform = usePlatform('salesforce')
	const [errors, setErrors] = useState<string[]>()
	const [isRoleAllowed, setIsRoleAllowed] = useState(false)

	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const loggedUserRole = session.user?.AccessLevel

	const allowedRoles = ['EXTERNAL_ADMIN']

	const onDeleteConnection = () => {
		deactivateConnectionMutation.mutateAsync(
			{
				connectionName: connectionData.name
			},
			{
				onSuccess: () => {
					setDeleteModal(false)
					onCancelEdit()
					onDelete()
				}
			}
		)
	}

	const openSalesforceAuthenticator = () => {
		dispatch(setAuthOrigin(AuthOrigins.Connections))
		const instanceUrl = authenticationSummary.instanceUrl
		if (isC4SPlatform) {
			dispatch(
				authenticateAgainst3rdParty(
					'',
					disableC4SOAuth,
					enableManagedConnectedApp,
					enableC4SAuthMgmt,
					instanceUrl
				)
			)
		} else {
			const env = authenticationSummary.isSandbox
				? SalesforceEnvironment.Sandbox
				: SalesforceEnvironment.Production
			dispatch(
				authenticateAgainst3rdParty(
					'',
					disableC4SOAuth,
					enableManagedConnectedApp,
					enableC4SAuthMgmt,
					instanceUrl,
					env
				)
			)
		}
	}

	const onUpdateConnection = () => {
		const data: SnowflakeData = {
			connectionType: System.Snowflake,
			username: snowData?.username || currentConnection.username,
			organization: snowData?.organization || currentConnection.organization,
			domainName: snowData?.domainName || currentConnection.domainName,
			cloud: snowData?.cloud || currentConnection.cloud || SnowCloud.AWS,
			region: snowData?.region || currentConnection.region || SnowRegion.NA,
			cloudRegion: snowData?.cloudRegion || currentConnection.cloudRegion || SnowCloudRegion.USEast,
			nameConnection: connectionData.name
		}

		const handleSuccess = () => {
			setCurrentConnection(data)
			setMessage('connections.edit.success.message')
		}

		const handleError = (errors?: string[]) => {
			setHasErrorResponse(true)
			setMessage(errors ? errors[0] : 'connections.edit.error.message')
		}

		updateConnectionMutation.mutateAsync(
			{ connectionData: data, systemType: System.Snowflake },
			{
				onSuccess: (response) => {
					const responseUpdate = response as SuccessResponse

					if (responseUpdate.Success !== undefined) {
						responseUpdate.Success ? handleSuccess() : handleError(responseUpdate.Errors)
					} else {
						if (responseUpdate.status === 'Ready') {
							handleSuccess()
						} else {
							handleError(responseUpdate.Errors)
						}
					}
				}
			}
		)
	}

	const onCloseEdit = () => {
		if (isEdited) {
			setShowCloseModal(true)
		} else {
			onCancelEdit()
		}
	}

	const onSuccessEdit = (updatedConnectionDetail: ConnectionDetail) => {
		setCurrentConnection((prevConnection) => ({
			...prevConnection,
			nameConnection: updatedConnectionDetail.displayName
		}))

		connectionData.displayName = updatedConnectionDetail.displayName
		connectionData.updated = updatedConnectionDetail.updated
		onEditedSuccessfully(updatedConnectionDetail)
	}

	const getField = (title: string, data: string): ReactElement => {
		return (
			<div className={styles.fieldContainer}>
				<div className={styles.fieldTitle}>{title}</div>
				<div className={styles.fieldInfo}>{data}</div>
			</div>
		)
	}

	const onCancelReauthenticate = () => {
		setReauthenticateModal(false)
	}

	const onCloseFailModal = () => {
		dispatch(clearApiErrorAction())
		setFailC4SModal(false)
	}

	const onRetry = () => {
		setFailC4SModal(false)
		setReauthenticateModal(true)
	}

	const getTabElement = (): ReactElement => {
		const date = new Date()
		switch (currentTab) {
			case TabIndexValue.information:
				return (
					<>
						<div className={styles.section}>
							<div className={styles.sectionTitle}>{t('connection.information')}</div>
							<div className={styles.infoData}>
								{getField(t('connection.created.by'), connectionData.user || '')}
								{getField(
									t('connection.created.date'),
									getLocalizedDateString(connectionData.created || date)
								)}
								{getField(
									t('connection.updated.date'),
									getLocalizedDateString(connectionData.updated || date)
								)}
							</div>
						</div>
						<div className={styles.section}>
							<div className={styles.sectionTitle}>{t('file.previewer.line.4')}</div>
							<ConnectionSources connectionName={connectionData.name} platform={connectionData.system} />
						</div>
					</>
				)
			case TabIndexValue.edit:
				return (
					<div
						style={{
							pointerEvents: isRoleAllowed ? 'auto' : 'none',
							opacity: isRoleAllowed ? 1 : 0.5
						}}
					>
						{connectionData.system === System.Salesforce && (
							<>
								<div className={styles.saveTemplateButtonWrapper}>
									{isC4SPlatform && orgId !== connectionData.displayName && (
										<div className={styles.orgMessage}>
											{t('connection.reaunthenticate.different.org')}
										</div>
									)}
									<DNBButton
										size="default"
										variant="secondary"
										onClick={() => setReauthenticateModal(true)}
										data-testid="save-template-button"
										disabled={isC4SPlatform && orgId !== connectionData.displayName}
									>
										<span>
											<Icon
												testId="chevron-left-user-menu"
												type="reload"
												size="mini"
												color={ColorBlueBrand}
											/>
										</span>
										<span className={styles.refreshIconSpace}>
											{t('connection.reaunthenticate')}
										</span>
									</DNBButton>
								</div>
								<div className={styles.section}>
									<div className={styles.sectionTitle}>{t('connection.delete')}</div>
									<div className={styles.infoData}>
										<DNBButton
											size="small"
											variant="destructive"
											onClick={() => setDeleteModal(true)}
											data-testid="delete"
											className={styles.deleteButton}
											disabled={deactivateConnectionMutation.status === 'loading'}
										>
											<span>
												<Icon
													testId="chevron-left-user-menu"
													type="trash"
													size="mini"
													color={ColorWhite}
												/>
											</span>
											<span className={styles.space}>{t('connections.delete.title')}</span>
										</DNBButton>
									</div>
								</div>
							</>
						)}

						{connectionData.system === System.ConnectManage && (
							<div className={styles.saveTemplateButtonWrapper}>
								<EditConnectManageConnection
									onDeleteConnection={onDeleteConnection}
									connection={connectionData}
									onCancelEdit={() => onCloseEdit()}
									onSuccessEdit={(updatedConnectionDetail) => {
										onSuccessEdit(updatedConnectionDetail)
									}}
								/>
							</div>
						)}
					</div>
				)
			default:
				return <></>
		}
	}

	useEffect(() => {
		if (
			!isEmpty(snowData?.username) &&
			!isEmpty(snowData?.organization) &&
			!isEmpty(snowData?.domainName) &&
			(snowData?.username !== currentConnection.username ||
				snowData?.organization !== currentConnection.organization ||
				snowData?.domainName !== currentConnection.domainName)
		) {
			setIsEdited(true)
		} else {
			setIsEdited(false)
		}
	}, [snowData, currentConnection])

	useEffect(() => {
		const addStorageEventListener = () => storageEventListener(dispatch, (display) => () => display)

		window.addEventListener('storage', addStorageEventListener, false)

		return () => {
			window.removeEventListener('storage', addStorageEventListener)
		}
		/**
		 * We want to run this effect when the salesforce oAuth returns an error
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (connection.createQueryStatus === CreateQueryStatus.Error) {
			setFailC4SModal(true)
			setErrors(connection.connectionErrors)
		}
		/**
		 * We want to run this effect when the salesforce oAuth is successfully
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection.createQueryStatus])

	useEffect(() => {
		setIsRoleAllowed(allowedRoles.includes(loggedUserRole))
	}, [loggedUserRole])

	return (
		<>
			<C4SFailConnectionModal
				open={failC4SModal}
				onClose={onCloseFailModal}
				onRetry={() => onRetry()}
				errors={errors}
				testId={'new-salesforce-modal'}
			/>
			<div className={styles.mainContainer} data-testid={`${testId}-editConnection`}>
				<div className={styles.deleteModal}>
					<Modal open={deleteModal} showButtonClose={false} testId="delete-connection-modal" fixWidth={false}>
						<div className={styles.deleteModalContainer}>
							<Icon type={'notification'} color={ColorScaleRed} />
							<div className={styles.deleteTitle}>{t('connections.delete.title')}</div>
							<div className={styles.warningText}>
								<Trans
									i18nKey="connection.delete.description.line1"
									values={{ connectionName: connectionData.displayName }}
								/>
							</div>
							<div className={styles.warningText}>{t('connection.delete.description.line2')}</div>
							<div className={styles.warningText}>{t('connection.delete.description.line3')}</div>
							<div className={styles.deleteDivider} />
							<div className={styles.deleteButtons}>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => setDeleteModal(false)}
									data-testid="cancel-delete"
									disabled={deactivateConnectionMutation.status === 'loading'}
								>
									{t('confirmation.modal.cancel')}
								</DNBButton>
								<DNBButton
									size="small"
									variant="destructive"
									onClick={() => onDeleteConnection()}
									data-testid="delete"
									className={styles.deleteButton}
									disabled={deactivateConnectionMutation.status === 'loading'}
								>
									{t('connection.delete.button')}
								</DNBButton>
							</div>
						</div>
					</Modal>
					<Modal
						open={reauthenticateModal}
						showButtonClose={false}
						testId="reauthenticate-connection-modal"
						fixWidth={false}
					>
						<div className={styles.deleteModalContainer}>
							<div className={styles.reauthenticateTile}>{t('connection.reauthenticate.title')}</div>
							{isC4SPlatform ? (
								<>
									<div className={styles.warningText}>
										<Trans
											i18nKey="connection.reauthenticate.description.line1"
											values={{ connectionName: orgId }}
										/>
									</div>
									<div className={styles.warningText}>
										<Trans i18nKey="connection.reauthenticate.description.line2" />
									</div>
								</>
							) : (
								<>
									<div className={styles.warningText}>
										<Trans
											i18nKey="connection.reauthenticate.description.standalone.line1"
											values={{ connectionName: connectionData.displayName }}
										/>
									</div>
									<div className={styles.warningText}>
										<Trans i18nKey="connection.reauthenticate.description.standalone.line2" />
									</div>
									<div className={styles.warningText}>
										<Trans i18nKey="connection.reauthenticate.description.standalone.line3" />
									</div>
								</>
							)}
							<div className={styles.deleteDivider} />
							<div className={styles.deleteButtons}>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => onCancelReauthenticate()}
									data-testid="cancel-reuthenticate"
									disabled={deactivateConnectionMutation.status === 'loading'}
								>
									{t('confirmation.modal.cancel')}
								</DNBButton>
								<DNBButton
									size="small"
									variant="primary"
									onClick={() => openSalesforceAuthenticator()}
									data-testid="reuthenticate"
									className={styles.deleteButton}
									disabled={deactivateConnectionMutation.status === 'loading'}
								>
									{t('confirmation.modal.continue')}
								</DNBButton>
							</div>
						</div>
					</Modal>
					<Modal open={showCloseModal} showButtonClose={false} testId="close-edit-modal" fixWidth={false}>
						<div className={styles.deleteModalContainer}>
							<div className={styles.warningText}>{t('connections.warning.edit')}</div>
							<div className={styles.deleteDivider} />
							<div className={styles.closeButtons}>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => setShowCloseModal(false)}
									data-testid="save-edit"
									className={styles.button}
								>
									{t('connections.edit.save.continue')}
								</DNBButton>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => {
										setShowCloseModal(false)
										onCancelEdit()
									}}
									data-testid="cancel-edit"
									className={styles.button}
								>
									{t('connections.edit.cancel')}
								</DNBButton>
							</div>
						</div>
					</Modal>
				</div>
				{isSalesforce && (
					<div className={styles.link}>
						<a className={styles.linkText} onClick={() => onCancelEdit()}>
							{t('dashboard.connections.title')}
						</a>
						{` / ${currentConnection.nameConnection}`}
					</div>
				)}
				<div className={styles.titleContainer}>
					<img
						className={styles.connectionImg}
						src={
							connectionData.system === System.Salesforce
								? salesforce
								: connectionData.system === System.Snowflake
								? snowflakes
								: dbconnect
						}
						alt={'connectionImg'}
					/>
					<div className={styles.texts}>
						<h2 className={styles.title}>
							<div className={styles.titleStatus}>
								{currentConnection.nameConnection} &nbsp;
								<ConnectionStatus
									connectionStatus={currentConnectionStatus || StatusType.Failed}
									isStatusLoading={isConnectionStatusLoading}
								/>
							</div>
							{!isSalesforce && (
								<div className={styles.close} onClick={() => onCloseEdit()}>
									x
								</div>
							)}
						</h2>

						<div className={styles.system}>
							<div className={styles.texts}>
								<div className={styles.subtitleStatus}>
									{connectionData.system} &nbsp;
									{connectionData.system === System.Salesforce && (
										<>
											{authenticationSummary?.instanceUrl} &nbsp;
											{authenticationSummary?.isSandbox ? (
												<ConnectionStatus
													connectionStatus={ConnectionEnvironment.Sandbox}
													isStatusLoading={false}
												/>
											) : (
												<ConnectionStatus
													connectionStatus={ConnectionEnvironment.Production}
													isStatusLoading={false}
												/>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.connectionSettings}>
					{isSalesforce ? (
						<>
							<Tabs
								colors={{
									defaultColor: ColorBackgroundModalTransparency,
									hoverColor: ColorBackgroundModalTransparency
								}}
								testId={'connection-detail'}
								onChange={(idx) => setCurrentTab(idx)}
								value={currentTab}
								type={'link'}
							>
								<Tab
									label={t('connection.information') || ''}
									key={'connectionInformation'}
									id="connectionInformation"
									aria-controls="connectionDetail"
								/>
								<Tab
									label={t('connections.edit') || ''}
									key={'connectionEdit'}
									id="connectionInformation"
									aria-controls="connectionDetail"
								/>
							</Tabs>
						</>
					) : (
						<div className={styles.settingTitle}>{t('connections.edit.settings')}</div>
					)}

					<Divider color={ColorGray} />

					{!isSalesforce && (
						<div className={styles.buttonsSection}>
							<div className={styles.leftButtons}>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => setDeleteModal(true)}
									data-testid="create-project"
									className={styles.button}
								>
									<div className={styles.icon}>
										<Icon
											testId="chevron-left-user-menu"
											type="trash"
											size="mini"
											color={ColorBlueBrand}
										/>
									</div>
									{t('connections.delete')}
								</DNBButton>
							</div>
							<div className={styles.rightButtons}>
								<DNBButton
									size="small"
									variant="secondary"
									onClick={() => onCloseEdit()}
									data-testid="cancel-update"
									className={styles.button}
								>
									{t('confirmation.modal.cancel')}
								</DNBButton>
								<DNBButton
									size="small"
									variant="primary"
									onClick={() => onUpdateConnection()}
									data-testid="confirm-update"
									className={styles.button}
									disabled={!isEdited}
								>
									{t('text.apply')}
								</DNBButton>
							</div>
						</div>
					)}
					{isSalesforce ? (
						getTabElement()
					) : (
						<div className={styles.inputSection}>
							<div className={styles.sectionTitle}>{t('connections.edit.detail')}</div>
							<Divider color={ColorGray} />
							<div className={styles.sectionQuestion}>{t('connections.edit.detail.question')}</div>
							<SnowflakeForm
								isEditable={updateConnectionMutation.status !== 'loading' && enableSnowflakeConnection}
								onChangeForm={(response) => setSnowData(response)}
								connectionData={{
									name: currentConnection.nameConnection || connectionData.name,
									user: currentConnection.username,
									system: System.Snowflake,
									authenticationSummary: {
										organization: currentConnection.organization,
										domainName: currentConnection.domainName,
										cloud: currentConnection.cloud,
										region: currentConnection.region,
										cloudRegion: currentConnection.cloudRegion
									}
								}}
								size={'large'}
								editableCloudRegion={false}
								showPassword={false}
							/>
							{!isEmpty(message) && (
								<div className={styles.errorContainer}>
									<Snackbar
										title={t(message)}
										onClose={() => setMessage('')}
										type={hasErrorResponse ? SnackType.error : SnackType.success}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	)
}
