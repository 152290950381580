import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { DNBSelect, DNBSelectOption, DNBToggle } from '@dnb-uux-design-system/react'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connectData, ConnectManageModal } from '../../../../components/connect-manage-modal/connect-manage-modal'
import { Snackbar, SnackType } from '../../../../components/snackbar/snackbar'
import { useApi } from '../../../../hooks'
import { useAccessLevel } from '../../../../hooks/useEntitlements'
import { System } from '../../../../queries/api/getConnections'
import { useConnections } from '../../../../queries/useConnections'
import { CreateConnectionResponse, useCreateConnection } from '../../../../queries/useCreateConnection'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import styles from './match-connect-manage.module.scss'

export function MatchConnectManage(): ReactElement {
	const { t } = useTranslation()
	const apiClient = useApi((error: AxiosError) => {
		return Promise.reject(error)
	})
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const connectManageInfo = projectWizardState.currentProject.source.integrationInfo?.connectManageInfo
	const [isConnectManageEnabled, setIsConnectManageEnabled] = useState(
		connectManageInfo?.connectManageEnabled !== undefined ? connectManageInfo?.connectManageEnabled : true
	)
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const {
		data: connections,
		isLoading,
		refetch: refetchConnections
	} = useConnections(true, connection.createQueryStatus, System.ConnectManage)
	const [connectManageModal, setConnectManageModal] = useState(false)
	const createConnectionMutation = useCreateConnection()
	const [isDataValid, setIsDataValid] = useState<boolean>(false)
	const [dataVerified, setDataVerified] = useState<boolean | undefined>(undefined)
	const dispatch: TDispatch = useAppDispatch()
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [selectedConnection, setSelectedConnection] = useState(connectManageInfo?.connectionName)
	const [externalSourceId, setExternalSourceId] = useState(connectManageInfo?.externalSourceId)
	const isAdmin = useAccessLevel(['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'])
	const { TokenSizing } = designTokens

	const verifiedData = async (systemType: string, data: connectData) => {
		const { apiUrl, apiKey } = { ...data }
		const checkDataAPI = `/pls/integration/${systemType}/organization/verifyConnection?apiKey=${apiKey}&apiUrl=${apiUrl}`
		await apiClient
			.get(checkDataAPI)
			.then((response) => {
				setIsDataValid(response.data.Success)
			})
			.catch(() => {
				setIsDataValid(false)
			})
		setDataVerified(true)
	}

	const createConnectConnection = (newConnectData: connectData) => {
		if (newConnectData) {
			createConnectionMutation.mutateAsync(
				{
					systemType: System.ConnectManage,
					connectionData: newConnectData
				},
				{
					onSuccess: async (response) => {
						const connectionResponse = response as CreateConnectionResponse

						if (connectionResponse.Success !== undefined && connectionResponse.Success) {
							setConnectManageModal(false)
							setDataVerified(undefined)
							await refetchConnections()
							setSelectedConnection(connectionResponse.name)

							updateEnableConnectManage(connectionResponse.name, externalSourceId, isConnectManageEnabled)

							setShowSnackbar(true)
						} else if (connectionResponse.name !== undefined && connectionResponse.name.trim() !== '') {
							setConnectManageModal(false)
							setDataVerified(undefined)
							await refetchConnections()
							setSelectedConnection(connectionResponse.name)
							updateEnableConnectManage(connectionResponse.name, externalSourceId, isConnectManageEnabled)

							setShowSnackbar(true)
						}
					},
					onError: (error) => {
						console.error('Error creating the connection:', error)
						setShowSnackbar(true)
					}
				}
			)
		}
	}

	useEffect(() => {
		if (connections && connections.length === 0 && !isLoading) {
			setShowSnackbar(true)
		} else {
			setShowSnackbar(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connections, isLoading])

	const [isFirstRender, setIsFirstRender] = useState(true)

	useEffect(() => {
		if (!isFirstRender && selectedConnection) {
			const updatedConnection = connections?.find((connection) => connection.name === selectedConnection)
			if (updatedConnection) {
				updateEnableConnectManage(selectedConnection, externalSourceId, isConnectManageEnabled)
				setConnectManageModal(false)
			}
		}
		setIsFirstRender(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connections, selectedConnection])

	const updateEnableConnectManage = (selectedOption: string, sourceId: string, isEnable: boolean) => {
		const displayName = connections?.find((connection) => connection.name.trim() === selectedOption)
		dispatch(
			updateCurrentProjectAction({
				source: {
					import_type: System.ConnectManage,
					integrationInfo: {
						connectManageInfo: {
							connectionName: selectedOption,
							displayName: displayName?.displayName,
							connectManageEnabled: isEnable,
							externalSourceId: sourceId
						}
					}
				}
			})
		)
	}

	const handleToggleChange = () => {
		setIsConnectManageEnabled(!isConnectManageEnabled)
		updateEnableConnectManage(selectedConnection, externalSourceId, !isConnectManageEnabled)
	}

	const handleSelectChange = (value: string) => {
		let currentValue = value

		if (!isEmpty(connectManageInfo?.connectionName) && isEmpty(value)) {
			currentValue = connectManageInfo?.connectionName
		}

		if (isEmpty(currentValue)) {
			setExternalSourceId('')
		}

		setSelectedConnection(currentValue)
		updateEnableConnectManage(currentValue, externalSourceId, isConnectManageEnabled)
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value
		setExternalSourceId(inputValue)
		updateEnableConnectManage(selectedConnection, inputValue, isConnectManageEnabled)
	}

	return (
		<>
			<ConnectManageModal
				open={connectManageModal}
				onClose={() => setConnectManageModal(false)}
				verifyData={verifiedData}
				dataVerified={dataVerified}
				testId={'newConnectManageModal'}
				changeData={() => setDataVerified(undefined)}
				isDataValid={isDataValid}
				onSave={createConnectConnection}
			/>
			<div className={styles.matchConnectManageStep}>
				<h1 data-testid="matching-connect-manage-step">{t('matching.step.connect.manage')}</h1>
				<span className={styles.description}>{t('matching.step.connect.manage.description')}</span>
				<div className={styles.connectManageToggle}>
					<span className={styles.description}>{t('matching.step.connect.manage.send')}</span>
					<span className={styles.trademark}>{t('matching.step.connect.manage.tm')}</span>
					<DNBToggle onChange={handleToggleChange} checked={isConnectManageEnabled} isLoading={false} />
				</div>
				<div className={styles.banner}>
					{showSnackbar && (
						<Snackbar
							title={t('matching.step.connect.manage.banner.title')}
							message={t('matching.step.connect.manage.banner.description') ?? ''}
							linkText={''}
							type={SnackType.warning}
							isBanner
						/>
					)}
				</div>
				<div className={styles.connectTitleDropList}>
					<span>{t('matching.step.connect.manage.connection')}</span>
					<span className={`${styles.trademark} ${styles.redText}`}>*</span>
					<div className={styles.connectDropList}>
						<DNBSelect
							id={`connect-manage-selector`}
							size="default"
							minWidth={TokenSizing.SizingWidthModal}
							onChange={(_event: Event, selection: string) => handleSelectChange(selection)}
							placeholder={t('matching.step.connect.manage.select.connection') || ''}
							disabled={!isConnectManageEnabled}
							value={selectedConnection || ''}
						>
							{connections && connections.length > 0 ? (
								connections.map((option) => (
									<DNBSelectOption
										key={option.name}
										value={option.name}
										disabled={option.status !== 'Ready'}
									>
										{option.displayName}
									</DNBSelectOption>
								))
							) : (
								<DNBSelectOption value="" disabled>
									{isLoading
										? t('Loading...')
										: t('matching.step.connect.manage.no.connection.available')}
								</DNBSelectOption>
							)}
						</DNBSelect>
						{isAdmin && (
							<a onClick={() => setConnectManageModal(true)}>
								{t('matching.step.connect.manage.create.new')}
							</a>
						)}
					</div>
				</div>

				<div className={styles.connectTitleDropList}>
					<span>{t('matching.step.connect.manage.soruceid')}</span>
					<span className={`${styles.trademark} ${styles.redText}`}>*</span>
					<div className={styles.inputWrapper}>
						<input
							id={`sourceId-input`}
							className={styles.textInput}
							type="text"
							placeholder={t('matching.step.connect.manage.select.cm.sourceid') || ''}
							onChange={handleInputChange}
							disabled={!isConnectManageEnabled || !selectedConnection}
							value={externalSourceId}
						/>
					</div>
					<div className={styles.requiredDescription}>
						<span>{t('matching.step.connect.manage.sourceid.required')}</span>
						<span className={styles.trademark}>{t('matching.step.connect.manage.tm')}</span>
					</div>
				</div>
			</div>
		</>
	)
}
