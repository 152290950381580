import { Divider } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../../design-tokens/build/shared/token-colors.json'
import { getLocalizedDateString } from '../../helpers'
import { System } from '../../queries/api/getConnections'
import { useConnectionSources } from '../../queries/useConnectionSources'
import { Pagination } from '../pagination/pagination'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { Spinner } from '../spinner/spinner'
import styles from './connection-source.module.scss'

interface Props {
	connectionName: string
	platform?: System
}

type SortOrder = 'asc' | 'desc'

export const ConnectionSources = ({ connectionName, platform }: Props) => {
	const { t } = useTranslation()
	const inactiveSourcesDisplay = platform === System.ConnectManage
	const sourcesData = useConnectionSources(connectionName || '', inactiveSourcesDisplay)
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const { data, isLoading } = sourcesData

	const startIndex = (currentPage - 1) * itemsPerPage
	const endIndex = startIndex + itemsPerPage

	const [sortColumn, setSortColumn] = useState<string>('name')
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc')

	const handleItemsPerPageChange = (value: number) => {
		setItemsPerPage(value)
		setCurrentPage(1)
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}

	const handleNextPage = () => {
		if (data && currentPage * itemsPerPage < data.length) {
			setCurrentPage(currentPage + 1)
		}
	}

	if (isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		)
	}

	const handleSort = (column: string) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortColumn(column)
			setSortOrder('asc')
		}
	}

	const sortedData = [...data].sort((a, b) => {
		if (sortColumn === 'creationDate') {
			return sortOrder === 'asc'
				? new Date(a.created).getTime() - new Date(b.created).getTime()
				: new Date(b.created).getTime() - new Date(a.created).getTime()
		}
		if (sortColumn === 'createdBy') {
			return sortOrder === 'asc' ? a.createdBy.localeCompare(b.createdBy) : b.createdBy.localeCompare(a.createdBy)
		}
		// Add other columns sorting logic if needed
		return sortOrder === 'asc'
			? a[sortColumn].localeCompare(b[sortColumn])
			: b[sortColumn].localeCompare(a[sortColumn])
	})

	const displayedItems = sortedData.slice(startIndex, endIndex)

	return (
		<>
			{!data || data.length === 0 ? (
				<Snackbar title={t('connection.no.sources')} type={SnackType.notification} isBanner />
			) : (
				<div className={styles.connectionsContainer}>
					<section className={styles.tableContainer}>
						<div className={styles.divider} />
						<div className={styles.tableHeader}>
							<div className={`${styles.nameCol}`} onClick={() => handleSort('name')}>
								{t('connection.source.name')}
							</div>
							{platform === System.ConnectManage && (
								<div className={`${styles.cmCol}`} onClick={() => handleSort('externalSourceId')}>
									{t('connection.source.connetion.manage')}
								</div>
							)}
							{platform === System.Salesforce && (
								<>
									<div className={`${styles.typeCol}`} onClick={() => handleSort('schemaTYpe')}>
										{t('connection.source.type')}
									</div>

									<div className={`${styles.sfdcCol}`} onClick={() => handleSort('object')}>
										{t('connection.source.object')}
									</div>
								</>
							)}
							<div className={`${styles.createCol}`} onClick={() => handleSort('creationDate')}>
								{t('connection.source.creation.date')}
							</div>
							<div className={`${styles.createByCol}`} onClick={() => handleSort('createdBy')}>
								{t('connection.source.created.by')}
							</div>
							<div className={`${styles.statusCol}`}>{t('connection.source.status')}</div>
						</div>
						{displayedItems?.map((ConnectionSource, index) => (
							<div key={index}>
								{index === 0 ? <div className={styles.dividerG} /> : <Divider color={ColorGrayDark} />}

								<div className={styles.row}>
									<div className={`${styles.nameCol}`}>{ConnectionSource.projectName}</div>
									{platform === System.ConnectManage && (
										<div className={`${styles.cmCol}`}>{ConnectionSource.externalSourceId}</div>
									)}
									{platform === System.Salesforce && (
										<>
											<div className={`${styles.typeCol}`}>
												{ConnectionSource.schemaTYpe === 'Contact' ? 'Contact' : 'Company'}
											</div>

											<div className={`${styles.sfdcCol}`}>{ConnectionSource.object}</div>
										</>
									)}
									<div className={`${styles.createCol}`}>
										{getLocalizedDateString(ConnectionSource.created)}
									</div>
									<div className={`${styles.createByCol}`}>{ConnectionSource.createdBy}</div>
									<div className={`${styles.statusCol} `}>
										<div
											className={
												ConnectionSource.archived ? `${styles.archive}` : `${styles.active}`
											}
										>
											{ConnectionSource.archived ? 'Inactive' : 'Active'}
										</div>
									</div>
								</div>
							</div>
						))}
						<div className={styles.divider} />
					</section>
					<Pagination
						options={[
							{ value: 10, label: '10' },
							{ value: 20, label: '20' },
							{ value: 30, label: '30' },
							{ value: 40, label: '40' },
							{ value: 50, label: '50' }
						]}
						totalItems={data.length}
						firstListItem={startIndex + 1}
						lastListItem={Math.min(endIndex, data.length)}
						onNextPage={handleNextPage}
						onPrevPage={handlePrevPage}
						onChangeItemsPerPage={(value) => handleItemsPerPageChange(value)}
					/>
				</div>
			)}
		</>
	)
}
