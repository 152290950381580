import {
	ColorBlueBrand,
	ColorHan,
	ColorHanLight,
	ColorHanLighter,
	ColorSlateLight,
	ColorTurquoise
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useApi } from '../../hooks'
import { useFeatures, useModules, usePlatform } from '../../hooks/useEntitlements'
import { Button, Divider, LoadingState, Tab, TabPanel, Tabs } from '../../local-core-ui'
import { getSources } from '../../queries/api/getSources'
import { useProject } from '../../queries/useProject'
import { useAppDispatch } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { readProjectIntoStore } from '../../store/projectWizard/thunks'
import { Source, UploadListResponse } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import { DashboardComponent } from '../dashboard-component/dashboard-component'
import { ProjectTeamMembers } from '../project-team-members/project-team-members'
import { SourceSummary } from '../source-summary/source-summary'
import styles from './project-summary.module.scss'

interface ProjectSummaryProps {
	projectId: string
	displayProjectSection: boolean
	testId: string
}

export const ProjectSummary: FC<ProjectSummaryProps> = ({
	projectId,
	displayProjectSection,
	testId
}: ProjectSummaryProps) => {
	const { t } = useTranslation()

	const primaryColor = ColorBlueBrand
	const secondaryColor = ColorTurquoise
	const primaryScssColor = '$color-blue-brand'
	const tertiaryScssColor = '$color-slate-lighter'
	const colorToken = 'ColorBlueBrand'

	const [hasUploads, setHasUploads] = useState<boolean>(false)
	const [hasErrors, setHasErrors] = useState<boolean>(false)
	const [tabSource, setTabSource] = useState<number>(0)
	const [sourceList, setSourceList] = useState<Array<Source>>([])

	const dispatch = useAppDispatch()
	const history = useHistory()

	const projectQuery = useProject(projectId)

	const apiClient = useApi()
	const isAPIEnabled = useFeatures(['EnableAPI'])
	const isSalesforce = usePlatform('salesforce')
	const isConnectManage = useModules(['ConnectManage'])

	let mounted = true
	const getSourcesList = async () => {
		await getSources(apiClient, projectId).then((response) => {
			setSourceList(response)
		})
	}

	const filteredSourceList = sourceList.filter(
		(source: Source) => !source.isFile || !(source.direct_enrich === undefined && source.enableAme === undefined)
	)

	useEffect(() => {
		getSourcesList().then()
	}, [])

	useEffect(() => {
		sourceList.forEach((s) => {
			apiClient
				.get(`/pls/uploads/sourceId/${s.sourceId}`)
				.then((uploadResponse: AxiosResponse<UploadListResponse>) => {
					if (mounted) {
						if (uploadResponse.data.length > 0) {
							setHasUploads(true)
						}
						uploadResponse.data.forEach((u) => {
							if (u.status === 'ERROR' && mounted) {
								setHasErrors(true)
							}
						})
					}
				})
		})
		return () => {
			/** TODO: Move "mounted" declaration inside the useEffect or store it in a useRef Hook and keep the mutable
			 * value in the '.current' property, because assignments to the 'mounted' variable from inside React Hook
			 * useEffect will be lost after each render.
			 */
			// eslint-disable-next-line react-hooks/exhaustive-deps
			mounted = false
		}
		// This should be executed only once and I can't include the definitions within
		// useEffect since apiClient is a hook, hence I'm disabling following warning
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceList])

	return (
		<div data-testid="project-summary" className={styles.projectSummary}>
			<div data-testid="ps-team-members" className={styles.psTeamMembers}>
				<h3>{t('team.members')}</h3>
				<div data-testid="ps-team-members-list-container" className={styles.psTeamMembersListContainer}>
					{projectQuery.data?.team?.TeamMembers ? (
						<ProjectTeamMembers
							teamMembers={projectQuery.data?.team?.TeamMembers}
							readOnly={true}
							testId={testId + '-team-members'}
						/>
					) : projectQuery.isFetching ? (
						<LoadingState />
					) : (
						t('no.team.members')
					)}
				</div>
			</div>
			{displayProjectSection && (
				<>
					<div className={styles.dividerContainer}>
						<Divider color={ColorSlateLight} />
					</div>
					<DashboardComponent
						mode="panels"
						level="Project"
						ownerId={projectId}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						primaryScssColor={primaryScssColor}
						tertiaryScssColor={tertiaryScssColor}
						colorToken={colorToken}
						hasUploads={hasUploads}
						hasErrors={hasErrors}
						testId={testId + '-dbc-project'}
						showContactGraphs={projectQuery.data?.sources.some(
							(src) => src.entityType === EntityType.CONTACTS
						)}
						EVforCurrentSrc={projectQuery.data?.sources.some((src) => src.enable_email_verification)}
					/>
					<Divider />
				</>
			)}
			<div className={styles.sourceSection}>
				<h2>{t('sources.added')} </h2>
				<Button
					text={t('add.source')}
					onClick={async () => {
						await dispatch(
							readProjectIntoStore(
								projectId,
								isAPIEnabled,
								isSalesforce,
								isConnectManage,
								false,
								undefined
							)
						)
						await dispatch(updateCurrentProjectAction({ shouldSync: true, deleteOnCancel: false }))
						history.push(`/steps/project-wizard/select-data?projectId=${projectId}`)
					}}
					type="secondary"
					iconType="plus"
					iconPosition="left"
					testId={testId + '-add-source-button'}
				/>
			</div>
			<div data-testid="sources-list-container-summary" className={styles.sourcesListContainer}>
				{filteredSourceList.length > 0 ? (
					<>
						<Tabs
							value={tabSource}
							onChange={(idx) => setTabSource(idx)}
							maxVisibleTabs={4}
							span={filteredSourceList.length > 4}
							colors={{ defaultColor: ColorHan, hoverColor: ColorHanLight }}
							testId={testId + '-tc-sources'}
						>
							{filteredSourceList.map((source: Source, idx: number) => (
								<Tab
									key={`tabSource-${idx}`}
									label={!source.isArchived ? source.sourceDisplayName : 'isArchived'}
									id={`tabSource-${source.sourceDisplayName}`}
									aria-controls={source.sourceDisplayName}
									tabColor={
										source.isArchived
											? { defaultColor: ColorHanLight, hoverColor: ColorHanLighter }
											: { defaultColor: ColorHan, hoverColor: ColorHanLight }
									}
								>
									<>
										{source.isArchived ? (
											<div className={styles.textArchived}>{source.sourceDisplayName}</div>
										) : null}
									</>
								</Tab>
							))}
						</Tabs>
						{filteredSourceList.map((source: Source, idx: number) => (
							<TabPanel
								key={'source-' + source.sourceId}
								value={tabSource}
								index={idx}
								id={source.sourceDisplayName}
								aria-labelledby={`tabSource-${source.sourceDisplayName}`}
								testId="sources"
							>
								{!source.isArchived ? (
									<SourceSummary
										sourceId={source.sourceId}
										sourceName={source.sourceDisplayName}
										isApiOnly={source.isApi && !source.isFile}
										projectId={projectId}
										testId={testId + '-source-summary-change-actions'}
										purposeOfUse={projectQuery.data?.purposeOfUse}
										entityType={source.entityType}
										EVforCurrentSrc={source.enable_email_verification || false}
										isCRMSource={source.integrationInfo?.integrationType === 'Salesforce'}
									/>
								) : (
									<div className={styles.sourceNote}>{t('archive.source.note')}</div>
								)}
							</TabPanel>
						))}
					</>
				) : (
					t('no.sources')
				)}
			</div>
		</div>
	)
}
