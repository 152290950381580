import { AxiosError, AxiosResponse } from 'axios'
import { isEmpty, split } from 'lodash-es'
import { mapFE2BEDelimiter } from '../../../helpers'
import i18n from '../../../i18n'
import { getC4SSourcePackageVersion } from '../../../queries/api/getSFPackageVersion'
import { CreateSourceRequest, CreateSourceResponse, DelimiterInfo, MapFieldDefinition } from '../../../types'
import { EntityType } from '../../../types/sources/EntityType'
import { IntegrationType } from '../../../types/sources/IntegrationInfo'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import {
	newProjectAction,
	selectProjectAction,
	setSFVersion,
	updateCurrentProjectAction,
	updateLoadingNextStep
} from '../actions'
import defaultContactFieldMapping from './default-field-mapping-4-contacts.json'
import defaultFieldMapping from './default-field-mapping.json'
import { getBaseMatchRule } from './getBaseMatchRule'
import { getDisplayNameForCrmSource } from './getDisplayNameForCrmSource'
import { getV2Mapping } from './getV2Mapping'

function getCrmSource(crmSource: string) {
	switch (crmSource) {
		case 'account':
			return 'Accounts'
		case 'contact':
			return 'Contacts'
		case 'lead':
			return 'Leads'
		default:
			return ''
	}
}

export const createOrUpdateSource =
	(
		passDelimiterInfo = false,
		isAPIEnabled: boolean,
		isSalesforce: boolean,
		isEnabledEnrichedOnlyMixedFile: boolean
	): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const project = getState().projectWizard.currentProject
		const thirdPartyIntegration = project.thirdPartyIntegration
		const sourceName = getState().projectWizard.currentProject.source.name || project?.name + ' - source'
		const uploadedFileInfo = getState().projectWizard.currentProject.fileInfo?.uploadInfo
		const isApi = getState().projectWizard.currentProject.source.isApi
		const isFile = getState().projectWizard.currentProject.source.isFile
		const sourceId = getState().projectWizard.currentProject.source?.id
		const isCRMSource = project.thirdPartyIntegration !== undefined
		const errorPackageVersion = 'LEDP_90003'

		if (project.id) {
			const request: CreateSourceRequest = {
				display_name: sourceName,
				project_id: project.id,
				field_definitions_record:
					project.source.entityType === EntityType.CONTACTS
						? defaultContactFieldMapping
						: defaultFieldMapping,
				isApi: project.source.isApi,
				isFile: project.source.isFile,
				enable_ame: project.source.enableAme,
				stewardable: project.source.stewardable
			}

			if (getState().projectWizard.currentProject.source.entityType === EntityType.CONTACTS) {
				request.enable_partial_match =
					getState().projectWizard.currentProject.source.enable_partial_match || false
			}
			if (getState().projectWizard.currentProject.source.directEnrich !== undefined) {
				if (isEnabledEnrichedOnlyMixedFile) {
					request.direct_enrich = project.source.directEnrich
				} else {
					request.direct_enrich = false
				}
			}

			dispatch(updateLoadingNextStep(true))
			if (isFile || (isCRMSource && !isApi) || (!isFile && !isApi)) {
				const fieldDefRecord =
					getState().projectWizard.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
				// TODO: THIS SHOULD BE REMOVED ONCE BE FIXES DCP-2238 if that ever happens
				const additionalDataDef = fieldDefRecord.fieldDefinitionsRecordsMap['additionalData']
				fieldDefRecord.fieldDefinitionsRecordsMap['additionalData'] = additionalDataDef.map((fieldDef) => {
					/*TODO: REMOVE CONDITION TO FIELDTYPE OF BOOLEAN WHEN BE FIX BUG RELATIONED TO DCP-2293 */
					return {
						columnName: fieldDef?.columnName,
						fieldName: fieldDef?.fieldName,
						fieldType:
							fieldDef?.fieldType === 'DATE' ||
							fieldDef?.fieldType === 'BOOLEAN' ||
							fieldDef?.fieldType === 'INTEGER'
								? 'TEXT'
								: fieldDef?.fieldType,
						inCurrentImport: fieldDef?.inCurrentImport,
						required: fieldDef?.required
					} as MapFieldDefinition
				})
				// END TODO

				if (passDelimiterInfo) {
					const fileInfo = getState().projectWizard.currentProject.fileInfo
					const delimiterInfo: DelimiterInfo = {
						detected_delimiter: mapFE2BEDelimiter(fileInfo?.autoDetectedDelimiter) || '',
						chosen_delimiter: mapFE2BEDelimiter(fileInfo?.delimiter) || '',
						escape: fileInfo?.escapeCharacter,
						quote: fileInfo?.textIdentifier
					}

					request.delimiter_info = delimiterInfo
				}
				request.file_import_id = uploadedFileInfo?.file_import_id
				request.field_definitions_record = fieldDefRecord
				request.inbound_document_type = ''

				// C4S type sources
				if (isCRMSource) {
					const orgId = getState().session.orgid || thirdPartyIntegration?.connectionName
					const c4SImportTpe = 'Salesforce'
					const c4SVersion = 'V2'
					if (project.thirdPartyIntegration?.crmSource) {
						const crmSource = getCrmSource(project.thirdPartyIntegration.crmSource)
						const displayName = getDisplayNameForCrmSource(project.thirdPartyIntegration.crmSource)
						request.display_name = project.source.name.startsWith(displayName)
							? project.source.name
							: `${displayName}${
									project.source.name.trim() !== '' ? '-'.concat(project.source.name) : ''
							  }`
						request.inbound_document_type = crmSource

						dispatch(updateCurrentProjectAction({ source: { name: request.display_name } }))
					}
					if (orgId) {
						request.external_system_id = orgId
					}
					request.import_type = c4SImportTpe
					request.version = c4SVersion
					request.delimiter_info = {
						...request.delimiter_info,
						detected_delimiter: 'COMMA',
						chosen_delimiter: 'COMMA'
					}
				}
				// End of C4S type sources

				// Connect Manage source type
				const connectManageInfo = project.source.integrationInfo?.connectManageInfo

				if (isFile && !isEmpty(connectManageInfo)) {
					request.enable_connect_manage = connectManageInfo?.connectManageEnabled
					request.external_source_id = connectManageInfo?.externalSourceId
					request.connection_name = connectManageInfo?.connectionName
					request.import_type = IntegrationType.ConnectManage
				}
				// End Connect Manage source type
			}

			const url = '/pls/sources'
			const apiClient = getApiClient4Thunks(dispatch, (error: AxiosError) => {
				const responseData = error.response?.data
				const errorCode = responseData?.errorCode
				if (errorCode !== errorPackageVersion) {
					alert(JSON.stringify(responseData) || error)
				}

				return Promise.reject(error)
			})

			let apiCall
			if (sourceId) {
				if (getState().projectWizard.currentProject.source.entityType === EntityType.CONTACTS) {
					request.enable_email_verification =
						getState().projectWizard.currentProject.source.enable_email_verification
					request.company_match_type = getState().projectWizard.currentProject.source.company_match_type
				}
				apiCall = apiClient.put(url, {
					...request,
					source_id: sourceId
				})
			} else {
				apiCall = apiClient.post(url, request)
			}
			const projectId = getState().projectWizard.currentProject.id

			return apiCall
				.then(async (response: AxiosResponse<CreateSourceResponse>) => {
					if (!getState().projectWizard.currentProject.id && isCRMSource && projectId) {
						dispatch(selectProjectAction(projectId))
						dispatch(updateCurrentProjectAction({ source: { id: response.data.sourceId } }))
						dispatch(newProjectAction(isAPIEnabled, isSalesforce))
					}
					if (isCRMSource) {
						getC4SSourcePackageVersion(apiClient, response.data.sourceId, project.versionSF).then(
							(response) => {
								if (response.version) {
									const versionSplit = split(response.version, '.')
									if (versionSplit[0] === '0') {
										const additionalInfo = ''
										const errorCode = errorPackageVersion
										const errorData: ApiErrorState = {
											additionalInfo,
											errorCode
										}

										dispatch(setApiErrorAction(errorData))
									}
									dispatch(setSFVersion(response.version || ''))
								}
							}
						)
					}
					dispatch(updateCurrentProjectAction({ source: { id: response.data.sourceId } }))
					dispatch(
						updateCurrentProjectAction({
							source: {
								enable_email_verification: response.data.enable_email_verification,
								company_match_type: response?.data?.company_match_type
							}
						})
					)
					await dispatch(getBaseMatchRule(response.data))
					if (isFile) {
						await dispatch(getV2Mapping(passDelimiterInfo))
					}
					dispatch(updateLoadingNextStep(false))
				})
				.catch((e) => {
					console.error(e)
					dispatch(updateLoadingNextStep(false))
					let errorMessage = JSON.parse(e.request.response).UIAction
					if (errorMessage) {
						const errorData: ApiErrorState = {
							errorCode: errorMessage.code,
							message: errorMessage.message
						}
						if (errorMessage.message.includes('LEDP_00002')) {
							errorData.message = i18n.t('error.message.new.source.project.archived') as string
						}
						dispatch(setApiErrorAction(errorData))
					} else {
						errorMessage = JSON.parse(e.request.response)
						const errorData: ApiErrorState = {
							errorCode: errorMessage.errorCode,
							message: errorMessage.errorMessage
						}
						dispatch(setApiErrorAction(errorData))
					}
					throw e
				})
		} else {
			return new Promise<void>((resolve, reject) => reject())
		}
	}
