import { MatchRuleInfo } from '../../../../store/projectWizard/types'

export const getInclusionCriteria = (rule: MatchRuleInfo, enableNonmarketableInclusion: boolean) => {
	const inclusionCriteriaKeys = ['NonHeadQuarters', 'OutOfBusiness', 'Unreachable', 'NonMarketable', 'Undeliverable']
	return arrayDifference(enableNonmarketableInclusion, inclusionCriteriaKeys, rule.matchRule.exclusionCriterion)?.map(
		(includeKey) => {
			return `match.rule.summary.tile.${includeKey}`
		}
	)
}

const arrayDifference = (enableNonmarketableInclusion: boolean, array1: string[], array2?: string[]) => {
	let difference: string[] = array1
	if (array2) {
		difference = array1.filter((key: string) => {
			if (key === 'NonMarketable' && !enableNonmarketableInclusion) {
				return false
			}
			return !array2.includes(key)
		})
	}
	return difference
}
