import { clone, isEmpty } from 'lodash-es'
import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'

import {
	WizardComposerStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import { createOrUpdateSource, validateMapping } from '../../../store/projectWizard/thunks'
import { validateAdditionalCountry } from '../../../store/projectWizard/thunks/validateAdditionalCountry'
import { validateGroupsMapping } from '../../../store/projectWizard/thunks/validateGroupsMapping'
import { FieldDefinitionsRecordsMap, MapFieldDefinition } from '../../../types'
import { EntityType } from '../../../types/sources/EntityType'
import alternativeElements from './alternative-elements.json'
import elementsToCopy from './elements-to-copy.json'
import { EmailVerification } from './file-mapping/email-verification'
import { FileMappingV2 } from './file-mapping/file-mapping-v2'
import groupsByEntityType from './groups-by-entity-type.json'
import requiredElementList from './required-elements.json'

export const isEnableEmailVerification = (context?: WizardContext): boolean => {
	if (isEmpty(context)) {
		return false
	} else {
		const isSalesforce = !isEmpty(context.projectWizardState.currentProject.thirdPartyIntegration)
		return isSalesforce
			? context.enableEmailVerification && context.enableEmailVerificationC4S
			: context.enableEmailVerification
	}
}
const isDataMappingReadyToValidate = (
	fieldDefinitionsRecordsMap: FieldDefinitionsRecordsMap,
	entityType: EntityType,
	context?: WizardContext
): boolean => {
	let atLeastOneSectionValid = false

	function hasAlternativeFieldMapped(fieldName: string, fieldDefinitionsRecordsMapElement: MapFieldDefinition[]) {
		if (Object.keys(alternativeElements).includes(fieldName)) {
			// can have an alternative field => check if alternative is mapped
			const alternativeKey = Object.entries(alternativeElements).find((value) => value[0] === fieldName)
			if (alternativeKey !== undefined) {
				const alternativeField = fieldDefinitionsRecordsMapElement.find(
					(fieldDefinition) => fieldDefinition.fieldName === alternativeKey[1]
				)
				return alternativeField !== undefined && alternativeField.columnName
			}
		}
		return false
	}

	function hasAllRequiredFieldsMapped(group: string) {
		let validSection = true
		let atLeastAField = false
		const somethingToCopy = elementsToCopy
			.filter((copyDefintion) => copyDefintion.destinationKey === group)
			.map((copyDef) => {
				return fieldDefinitionsRecordsMap[copyDef.originKey]?.filter(
					(fieldDefinition) => fieldDefinition.fieldName === copyDef.field && copyDef.requiredInDestination
				)
			})
			.filter((group) => group !== undefined)
			.reduce((prevGroup, currentGroup) => [...prevGroup, ...currentGroup], [])

		const fieldDefinitionsRecordsMapElement = clone(fieldDefinitionsRecordsMap[group])
		fieldDefinitionsRecordsMapElement.push(...somethingToCopy)

		for (const mapFieldDefinition of fieldDefinitionsRecordsMapElement) {
			if (mapFieldDefinition.fieldName && requiredElementList.includes(mapFieldDefinition.fieldName)) {
				atLeastAField = true

				if (
					!mapFieldDefinition.columnName &&
					!hasAlternativeFieldMapped(mapFieldDefinition.fieldName, fieldDefinitionsRecordsMapElement)
				) {
					validSection = false
					break
				}
			}
		}

		return validSection && atLeastAField
	}

	if (!isEmpty(fieldDefinitionsRecordsMap)) {
		for (const idx in groupsByEntityType[entityType]) {
			let group
			isEnableEmailVerification(context) &&
			context?.projectWizardState.currentProject.source.entityType === EntityType.CONTACTS &&
			context?.projectWizardState.currentProject.source.enable_email_verification === true
				? (group = 'contactEmail')
				: (group = groupsByEntityType[entityType][idx])

			if (Object.keys(fieldDefinitionsRecordsMap).includes(group) && hasAllRequiredFieldsMapped(group)) {
				if (group === 'contactInfo') {
					for (const idx in groupsByEntityType[EntityType.ACCOUNTS]) {
						const accountGroup = groupsByEntityType[EntityType.ACCOUNTS][idx]

						if (
							Object.keys(fieldDefinitionsRecordsMap).includes(accountGroup) &&
							hasAllRequiredFieldsMapped(accountGroup)
						) {
							atLeastOneSectionValid = true
							break
						}
					}
				} else {
					atLeastOneSectionValid = true
					break
				}
			}
		}
	} else {
		atLeastOneSectionValid = false
	}

	// V2 does not need every section valid, it needs at least a valid section
	return atLeastOneSectionValid
}

export const WizardComposerMappingStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	const mappingSubStepDefinition = {
		title: 'file.mapping.substep.mapping',
		state: () => {
			const validationResponse = context.projectWizardState.currentProject.mappingInfo.validationResponse
			const mappingInfo = context.projectWizardState.currentProject.mappingInfo

			if (
				context.projectWizardState.currentProject.mappingInfo.isDataMappingValidated &&
				mappingInfo.areDataGroupsValidated &&
				mappingInfo.haveGroupsIncomplete &&
				validationResponse &&
				(validationResponse.validationResult !== 'ERROR' ||
					(validationResponse.fieldValidationMessagesMap.additionalInformation?.length === 0 &&
						validationResponse.fieldValidationMessagesMap.companyInformation?.length === 0 &&
						validationResponse.fieldValidationMessagesMap.uniqueIds?.length === 0))
			) {
				return WizardComposerSubStepStates.complete
			} else {
				return WizardComposerSubStepStates.pending
			}
		},
		onNext: async () => {
			await context
				.dispatch(
					validateAdditionalCountry(
						context.enableAlternateFieldsRecomm,
						context.projectWizardState.currentProject.source.entityType || EntityType.ACCOUNTS
					)
				)
				.then(async () => {
					await context
						.dispatch(validateGroupsMapping())
						.then(async () => {
							await context
								.dispatch(validateMapping())
								.then(async () => {
									if (
										context.projectWizardState.currentProject.source.entityType ===
										EntityType.CONTACTS
									) {
										await context
											.dispatch(
												createOrUpdateSource(
													context.passDelimiterInfo,
													context.enableApi,
													context.salesforcePlatform,
													context.enableEnrichedOnlyMixedFile
												)
											)
											.then(() =>
												context.queryClient.invalidateQueries([
													'getSources',
													{
														sourceId: context.projectWizardState.currentProject.source.id,
														inflateMetadata: false
													}
												])
											)
											.catch((err) => {
												throw err
											})
									}
								})
								.catch((err) => {
									if (err && err.validationResult === undefined) {
										throw err
									} else if (
										err &&
										(err.fieldValidationMessagesMap.additionalData.length !== 0 ||
											err.fieldValidationMessagesMap.companyInformation.length !== 0 ||
											err.fieldValidationMessagesMap.uniqueIds?.length !== 0)
									) {
										throw undefined
									}
								})
						})
						.catch(() => {
							throw undefined
						})
				})
				.catch(() => {
					throw undefined
				})
		},
		onPrevious: undefined,
		isNextEnabled: () =>
			isDataMappingReadyToValidate(
				context.projectWizardState.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
					.fieldDefinitionsRecordsMap,
				context.projectWizardState.currentProject.source.entityType || EntityType.ACCOUNTS
			) && !context.projectWizardState.loadingNextStep,
		route: { path: '/define', component: FileMappingV2 }
	}

	const emailVerificationSubStep = {
		title: 'file.mapping.substep.emailverification',
		state: () => {
			const validationResponse = context.projectWizardState.currentProject.mappingInfo.validationResponse
			if (
				context.projectWizardState.currentProject.mappingInfo.isDataMappingValidated &&
				validationResponse &&
				(validationResponse.validationResult !== 'WARNING' ||
					(validationResponse.fieldValidationMessagesMap.additionalInformation?.length === 0 &&
						validationResponse.fieldValidationMessagesMap.companyInformation?.length === 0 &&
						validationResponse.fieldValidationMessagesMap.uniqueIds?.length === 0)) &&
				context.projectWizardState.currentProject.source.enable_email_verification !== undefined
			) {
				return WizardComposerSubStepStates.complete
			} else {
				return WizardComposerSubStepStates.pending
			}
		},
		onNext: async () => {
			await context
				.dispatch(validateMapping())
				.then()
				.catch((err) => {
					if (err && err.validationResult === undefined) {
						throw err
					} else if (
						err &&
						(err.fieldValidationMessagesMap.additionalData.length !== 0 ||
							err.fieldValidationMessagesMap.companyInformation.length !== 0 ||
							err.fieldValidationMessagesMap.uniqueIds?.length !== 0)
					) {
						throw undefined
					}
				})
		},
		onPrevious: undefined,
		isNextEnabled: () =>
			isDataMappingReadyToValidate(
				context.projectWizardState.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
					.fieldDefinitionsRecordsMap,
				context.projectWizardState.currentProject.source.entityType || EntityType.CONTACTS,
				context
			) && !context.projectWizardState.loadingNextStep,
		route: { path: '/evverification', component: EmailVerification }
	}

	return {
		title: 'project.wizard.step.title.map.columns',
		progress: () =>
			context.projectWizardState.currentProject.mappingInfo.isDataMappingValidated
				? context.projectWizardState.currentProject.mappingInfo.validationResponse?.validationResult === 'ERROR'
					? 50
					: context.projectWizardState.currentProject.mappingInfo.isDataTypesValidated &&
					  context.projectWizardState.currentProject.mappingInfo.areDataGroupsValidated &&
					  !context.projectWizardState.currentProject.mappingInfo.haveGroupsIncomplete
					? context.projectWizardState.currentProject.source.id
						? 100
						: 90
					: 50
				: isDataMappingReadyToValidate(
						context.projectWizardState.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
							.fieldDefinitionsRecordsMap,
						context.projectWizardState.currentProject.source.entityType || EntityType.ACCOUNTS
				  )
				? 30
				: 0,
		onNext: async () => {
			await context
				.dispatch(
					createOrUpdateSource(
						context.passDelimiterInfo,
						context.enableApi,
						context.salesforcePlatform,
						context.enableEnrichedOnlyMixedFile
					)
				)
				.then(() =>
					context.queryClient.invalidateQueries([
						'getSources',
						{ sourceId: context.projectWizardState.currentProject.source.id, inflateMetadata: false }
					])
				)
				.catch((err) => {
					throw err
				})
		},
		onPrevious: undefined,
		subSteps:
			isEnableEmailVerification(context) &&
			context.projectWizardState.currentProject.source.entityType === EntityType.CONTACTS &&
			localStorage.getItem('isEVEntitled') === 'true'
				? [mappingSubStepDefinition, emailVerificationSubStep]
				: [mappingSubStepDefinition]
	}
}
