import { ColorOrange, ColorOrangeBurnt } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { SizingFontMedium } from '@dnb-dcp/design-tokens/build/shared/token-sizing.json'
import { DNBTooltip } from '@dnb-uux-design-system/react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { AxiosError, AxiosResponse } from 'axios'
import cx from 'classnames'
import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import {
	Button,
	ContextMenu,
	ContextMenuOption,
	ErrorState,
	Icon,
	LoadingState,
	Modal,
	ProgressBar,
	ToolTip
} from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import { LicenseType } from '../../store/session/types/LicenseType'
import { JobDetailsResponse, MatchErrorSummary, Upload, UploadType } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import { IntegrationType } from '../../types/sources/IntegrationInfo'
import { removeFileNameExtension } from '../../utilities'
import { AppIdCopyToClipboardModal } from '../appID-s3pathway-copy-modal/components/appIdCopyToClipboardModal'
import { Pill } from '../pill/pill'
import { ShowHideSectionIcon } from '../show-hide-section-icon/show-hide-section-icon'
import { copyToClipboard } from '../upload-status-details/upload-status-utilities'
import { startDownloadProcess } from './downloadFiles'
import { FilesDownloadModal } from './files-download-modal'
import { Stat, UploadTileStats } from './upload-tile-stats'
import styles from './upload-tile.module.scss'

export interface RelationUploads {
	selectedUploadVersion: Upload
	original: Upload
	versions?: Array<Upload>
}

export interface UploadTileProps {
	createdDate?: number
	expirationDate?: number
	stats: Array<Stat>
	progressBarColor?: string
	progressColor?: string
	buttonColors?: buttonStates
	statusTextColor?: string
	displayedSection: boolean
	onClickSelection(): void
	onClickUpdateNow?(): void
	onClickRefresh?(upload: Upload, originalUpload?: Upload): void
	onClickReport?(uploadId: string): void
	s3Pathway: string
	downloadableFiles?: Array<string>
	uploadApplicationId?: string
	testId: string
	showContextMenu?: boolean
	isApiBased?: boolean
	errorMessageUpload?: string
	secondaryButtonColors?: buttonStates
	showNotice?: boolean
	showUpdateNowButton?: boolean
	sourceEntityType?: EntityType
	sourceIntegrationType?: IntegrationType
	stewardshipDisabled?: boolean
	upload: Upload
	relationVersions?: RelationUploads
	onClickVersion?: (onVersion: Upload) => void
	isCancelRefresh: boolean
	isConnectManage: boolean
}

export type buttonColors = {
	textColor: string
	backgroundColor: string
	borderColor: string
}

export type buttonStates = {
	normalState: buttonColors
	hoverState: buttonColors
	focusState: buttonColors
	disabledState: buttonColors
}

const progressBackgroundColor = 'ColorOrangeBurntPowder'
const primaryColor = 'ColorOrange'
const primaryDarkColor = 'ColorOrangeBurnt'

export const defaultButtonColors = {
	normalState: {
		textColor: '',
		backgroundColor: primaryColor,
		borderColor: primaryColor
	},
	hoverState: {
		textColor: '',
		backgroundColor: primaryDarkColor,
		borderColor: primaryDarkColor
	},
	focusState: {
		textColor: '',
		backgroundColor: primaryDarkColor,
		borderColor: primaryDarkColor
	},
	disabledState: {
		textColor: '',
		backgroundColor: 'ColorOrange',
		borderColor: 'ColorOrange'
	}
}

export const defaultSecondaryButtonColors = {
	normalState: {
		textColor: primaryColor,
		backgroundColor: 'ColorWhite',
		borderColor: primaryColor
	},
	hoverState: {
		textColor: primaryDarkColor,
		backgroundColor: 'ColorWhite',
		borderColor: primaryDarkColor
	},
	focusState: {
		textColor: primaryDarkColor,
		backgroundColor: 'ColorWhite',
		borderColor: primaryDarkColor
	},
	disabledState: {
		textColor: 'ColorOrange',
		backgroundColor: 'ColorWhite',
		borderColor: 'ColorOrange'
	}
}

export const UploadTile = ({
	createdDate: createdDateTimestamp,
	errorMessageUpload = '',
	expirationDate: expirationDateTimestamp,
	stats,
	progressBarColor = progressBackgroundColor,
	progressColor = primaryColor,
	buttonColors = defaultButtonColors,
	statusTextColor = ColorOrange,
	displayedSection,
	onClickRefresh,
	onClickReport,
	onClickSelection,
	onClickUpdateNow = undefined,
	s3Pathway,
	downloadableFiles,
	testId,
	uploadApplicationId = '',
	showContextMenu = true,
	isApiBased = false,
	secondaryButtonColors = defaultSecondaryButtonColors,
	showNotice = true,
	showUpdateNowButton = false,
	sourceEntityType,
	sourceIntegrationType,
	stewardshipDisabled = false,
	upload,
	relationVersions,
	onClickVersion,
	isCancelRefresh,
	isConnectManage
}: UploadTileProps): ReactElement => {
	const { displayName, sourceId, status, uploadId, progressPercentage, type, uploadDiagnostics } = upload
	const { t } = useTranslation()
	const matchStatusMessage =
		type === UploadType.ENRICH_ONLY || type === UploadType.UPLOAD_REFRESH ? 'updating' : 'matching'
	const selectSession = (state: RootState) => state.session
	const apiClient = useApi()
	const session = useAppSelector(selectSession)
	const role = session.user?.AccessLevel
	const currentLicense = session.currentLicense
	const EnableTxnApiErrorReporting = useFeatures(['EnableTxnApiErrorReporting'])
	const EnableDataRetentionPolicy = useFeatures(['EnableDataRetentionPolicy'])
	const EnableFileLevelDashboardDownload = useFeatures(['EnableFileLevelDashboardDownload'])
	const EnableDiscoverRefresh = useFeatures(['EnableDiscoverRefresh'])
	const [statusMessage, setStatusMessage] = useState<string>('')
	const [statusPercentage, setStatusPercentage] = useState<number>(0)
	const [statusColor, setStatusColor] = useState(statusTextColor)
	const [displayFilesDownloadModal, setDisplayFilesDownloadModal] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>()
	const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false)
	const isC4S = useModules(['C4S'])
	const connectManageModule = useModules(['ConnectManage'])
	const genericErrorMessage = t('upload.title.loading.container.error.message')
	const applicationId = useRef(uploadApplicationId)
	const s3PathwayRef = useRef(s3Pathway)
	const [showConfirmationModalCopyToClipboard, setShowConfirmationModalCopyToClipboard] = useState(false)
	const [showConfirmationModalCopyS3, setShowConfirmationModalCopyS3] = useState(false)
	const [showErrorsDetailModal, setShowErrorsDetailModal] = useState(false)
	const [downloadableFilesAPI, setDownloadableFilesAPI] = useState<string[]>([])
	const [existsTXNErrors, setExistsTXNErrors] = useState<boolean>(false)
	const [dataDeleted, setDataDeleted] = useState<boolean>(false)
	const [dataExpired, setDataExpired] = useState<boolean>(false)
	const [displayDownloadButton, setDisplayDownloadButton] = useState(false)
	const [displayExpirationDate, setDisplayExpirationDate] = useState<boolean>(false)
	const [downloadDisabled, setDownloadDisabled] = useState(false)
	const [enrichOnly, setEnrichOnly] = useState(false)
	const [jobId, setJobId] = useState('')
	const [hasExpirationDate, setHasExpirationDate] = useState(false)
	const [isDateSoonToExpire, setIsDateSoonToExpire] = useState<boolean>(false)
	const [refreshDisabled, setRefreshDisabled] = useState(false)
	const [showRefreshButton, setShowRefreshButton] = useState(false)
	const [showRestartModal, setShowRestartModal] = useState(false)
	const [contextMenuOptions, setContextMenuOptions] = useState<Array<ContextMenuOption>>()
	const createdDate = new Date(createdDateTimestamp ?? 0)
	const expirationDate = new Date(expirationDateTimestamp ?? 0)
	const currentDate = new Date()
	const versionsUpload = relationVersions?.versions || []
	const selectedVersion = relationVersions?.selectedUploadVersion
	const originalVersion = relationVersions?.original
	const DAYS_ADVANCE_WARNING = 10

	const isRefreshDisabled = () => {
		let isDisable = false
		if (relationVersions) {
			const otherVersionUpdating = relationVersions?.versions?.filter(
				(versionUpload) => versionUpload.status !== 'ERROR' && versionUpload.status !== 'FINISHED'
			)
			if (
				(otherVersionUpdating && otherVersionUpdating?.length > 0) ||
				(relationVersions?.original.status !== 'ERROR' && relationVersions?.original.status !== 'FINISHED') ||
				status === 'CLEANED_UP'
			) {
				isDisable = true
			}
		}
		return isDisable
	}
	const getOptions = () => {
		const downloadActionDisabled = status === 'CLEANED_UP' || status !== 'FINISHED'

		const baseOptions: Array<ContextMenuOption> = [
			{
				label: t('upload.tile.context.menu.copy.pathway'),
				action: 'copyPathway',
				disabled: currentLicense.licenseType === LicenseType.BASIC_LICENSE,
				textTooltip:
					currentLicense.licenseType === LicenseType.BASIC_LICENSE
						? (t('tooltip.not.support.s3.basic.license') as string)
						: undefined
			},
			{
				label: t('upload.tile.context.menu.get.id'),
				action: 'getId',
				disabled: isEmpty(applicationId.current)
			},
			{
				label: t('upload.tile.context.menu.download'),
				action: 'download',
				disabled: downloadActionDisabled
			}
		]
		if (showRefreshButton) {
			baseOptions.push({
				label: t('upload.tile.context.menu.refresh'),
				action: 'refresh',
				disabled: isRefreshDisabled()
			})
		}

		if (EnableFileLevelDashboardDownload) {
			baseOptions.push({
				label: t('upload.tile.context.menu.view.downloadable'),
				action: 'showVersionPrintable',
				disabled:
					currentLicense.licenseType === LicenseType.LEGACY ||
					currentLicense.licenseType === LicenseType.BASIC_LICENSE,
				textTooltip:
					currentLicense.licenseType === LicenseType.LEGACY ||
					currentLicense.licenseType === LicenseType.BASIC_LICENSE
						? (t(`tooltip.not.support.version.printable.license`, {
								currentLicense: currentLicense.licenseType
						  }) as string)
						: undefined
			})
		}

		if (errorMessageUpload !== '' && (role === 'INTERNAL_ADMIN' || role === 'ADMIN')) {
			baseOptions.push({ label: t('upload.tile.context.menu.see.errors.detail'), action: 'seeErrorsDetail' })
		}

		if (connectManageModule && status === 'ERROR' && isConnectManage) {
			baseOptions.unshift({
				label: t('upload.tile.context.menu.restart'),
				action: 'restartJob',
				disabled: false
			})
		}

		return baseOptions
	}

	useEffect(() => {
		const someRefreshing = isRefreshDisabled()
		setRefreshDisabled(someRefreshing)
		setContextMenuOptions(getOptions())
		switch (status) {
			case 'NEW':
				setStatusMessage('queued')
				setDisplayDownloadButton(false)
				setStatusPercentage(progressPercentage || 0)
				setStatusColor(statusTextColor)
				break
			case 'IMPORT_STARTED':
				setStatusMessage('ingesting')
				setDisplayDownloadButton(false)
				setStatusPercentage(progressPercentage || 20)
				setStatusColor(statusTextColor)
				break
			case 'IMPORT_FINISHED':
				setStatusMessage('ingesting')
				setStatusPercentage(progressPercentage || 40)
				setStatusColor(statusTextColor)
				break
			case 'MATCH_STARTED':
				setStatusMessage(matchStatusMessage)
				setDisplayDownloadButton(false)
				setStatusPercentage(progressPercentage || 60)
				setStatusColor(statusTextColor)
				break
			case 'MATCH_FINISHED':
				setStatusMessage(matchStatusMessage)
				setDisplayDownloadButton(false)
				setStatusPercentage(progressPercentage || 80)
				setStatusColor(statusTextColor)
				break
			case 'FINISHED':
				setStatusMessage('')
				setDisplayDownloadButton(true)
				break
			case 'CLEANED_UP':
				setDataDeleted(true)
				setDisplayDownloadButton(true)
				break
			case 'ERROR':
				setStatusMessage('')
				setDisplayDownloadButton(true)
				break
			default:
				setStatusMessage('')
				setStatusPercentage(progressPercentage || 0)
				break
		}
	}, [
		status,
		statusPercentage,
		statusTextColor,
		progressPercentage,
		expirationDateTimestamp,
		uploadId,
		relationVersions?.selectedUploadVersion,
		isCancelRefresh
	])

	useEffect(() => {
		setContextMenuOptions(getOptions())
	}, [showRefreshButton, refreshDisabled])

	const getJobDetails = (mounted = true) => {
		setIsLoading(true)
		apiClient
			.get(`/pls/uploads/uploadId/${uploadId}/jobDetails`)
			.then((response: AxiosResponse<JobDetailsResponse>) => {
				const { uploadStats, uploadDiagnostics } = response.data
				const { matched, pendingReviewCnt, unmatched } = uploadStats?.match ?? {}
				const onlyEnrich =
					uploadStats === undefined || (matched === 0 && pendingReviewCnt === 0 && unmatched === 0)
				setEnrichOnly(onlyEnrich)
				setJobId(uploadDiagnostics.jobId)
				applicationId.current = response.data.uploadDiagnostics.applicationId
				if (mounted) setIsLoading(false)
			})
			.catch((error: AxiosError) => {
				if (mounted) setIsLoading(false)
				console.error(error.message)
				if (mounted) setErrorMessage(error.message)
			})
	}

	const getMatchErrorSummary = () => {
		apiClient
			.get(`/pls/transactional-match/matchErrorSummary/${sourceId}/ALL`)
			.then((response: AxiosResponse<MatchErrorSummary>) => {
				const matchErrorResponse: MatchErrorSummary = response.data
				const matchError: string[] = []
				let dateTile = ''

				if (createdDateTimestamp !== undefined)
					dateTile = createdDate.toISOString().slice(0, 7).replace(/-/g, '')

				matchErrorResponse.errorDates.INGESTION?.forEach((errorDate: string) => {
					if (errorDate === dateTile) matchError.push('IMPORT_ERRORS')
				})

				matchErrorResponse.errorDates.PUBLISHING?.forEach((errorDate: string) => {
					if (errorDate === dateTile) matchError.push('PUBLISH_ERRORS')
				})

				if (matchError.length === 0) setExistsTXNErrors(false)
				else setExistsTXNErrors(true)

				setDownloadableFilesAPI(matchError)
			})
			.catch((error: AxiosError) => {
				console.error(error.message)
			})
	}

	const getFiles = (fileTypesSelected: string) => {
		startDownloadProcess(
			createdDateTimestamp,
			isApiBased,
			sourceId,
			fileTypesSelected,
			setIsDownloading,
			uploadId,
			apiClient,
			genericErrorMessage,
			setErrorMessage
		)
	}

	const onMenuClicked = (action: string) => {
		switch (action) {
			case 'share':
				break
			case 'editSource':
				break
			case 'copyPathway':
				isC4S
					? setShowConfirmationModalCopyS3(true)
					: copyToClipboard(s3PathwayRef.current, setShowCopiedMessage)
				break
			case 'getId':
				isC4S
					? setShowConfirmationModalCopyToClipboard(!isEmpty(applicationId.current))
					: copyToClipboard(applicationId.current, setShowCopiedMessage)
				break
			case 'download':
				setDisplayFilesDownloadModal(true)
				break
			case 'seeErrorsDetail':
				setShowErrorsDetailModal(true)
				break
			case 'refresh':
				if (onClickRefresh) {
					setRefreshDisabled(true)
					if (relationVersions) onClickRefresh(upload, relationVersions?.original)
					else onClickRefresh(upload)
				}
				break
			case 'showVersionPrintable':
				if (onClickReport) {
					onClickReport(uploadId)
				}
				break
			case 'restartJob':
				restart()
				break
		}
	}

	const restart = () => {
		const url = `/pls/jobs/${jobId || uploadDiagnostics.jobId}/restart`
		apiClient.post(url).then((response) => {
			if (response.data.Success === true) {
				setShowRestartModal(true)
			}
		})
	}

	useEffect(() => {
		setShowRefreshButton(
			sourceIntegrationType === 'DCP' && EnableDiscoverRefresh && sourceEntityType === 'Accounts'
		)
	}, [EnableDiscoverRefresh, sourceIntegrationType])

	useEffect(() => {
		if (hasExpirationDate) {
			const timeDifference = expirationDate.getTime() - currentDate.getTime()
			const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24))

			setDataExpired(
				currentDate.getTime() > expirationDate.getTime() ||
					currentDate.toLocaleDateString() === expirationDate.toLocaleDateString()
			)

			if (daysDifference <= DAYS_ADVANCE_WARNING) {
				setIsDateSoonToExpire(true)
			}
		}
	}, [expirationDate, hasExpirationDate])

	useEffect(() => {
		let mounted = true
		if (!isEmpty(applicationId.current)) {
			getJobDetails(mounted)
		}

		if (downloadableFilesAPI.length === 0 && isApiBased) getMatchErrorSummary()
		// We only need to make this api call when a upload is selected to be displayed
		// If we add getJobDetails the api will make unnecessary calls
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when uploadId prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadId])

	useEffect(() => {
		s3PathwayRef.current = s3Pathway
	}, [s3Pathway])

	useEffect(() => {
		setDisplayExpirationDate(hasExpirationDate && EnableDataRetentionPolicy && displayDownloadButton)
	}, [EnableDataRetentionPolicy, hasExpirationDate, displayDownloadButton])

	useEffect(() => {
		setDownloadDisabled(dataDeleted && EnableDataRetentionPolicy)
	}, [dataDeleted, dataExpired, EnableDataRetentionPolicy])

	useEffect(() => {
		setHasExpirationDate(!!expirationDateTimestamp && expirationDateTimestamp > 0)
	}, [expirationDateTimestamp])

	return (
		<>
			<Modal
				isContainer={true}
				open={showErrorsDetailModal}
				onClose={() => {
					setShowErrorsDetailModal(false)
				}}
				testId="showErrorsDetailUploadModal"
			>
				<div data-testid="error-message-icon-container" className={styles.errorMessageModalAlertIconContainer}>
					<Icon testId="alert-big-error-modal" type="alert-big" size="medium" color={ColorOrangeBurnt} />
				</div>
				<div className={styles.modalText}>{errorMessageUpload}</div>
			</Modal>
			<Modal
				isContainer={true}
				open={showRestartModal}
				onClose={() => setShowRestartModal(false)}
				testId="showSuccessRestartModal"
			>
				<div data-testid="error-message-icon-container" className={styles.errorMessageModalAlertIconContainer}>
					<Icon testId="alert-big-error-modal" type="check-circle" size="medium" />
				</div>
				<div className={styles.modalText}>{'Workflow restarted successfully'}</div>
			</Modal>
			<AppIdCopyToClipboardModal
				open={showConfirmationModalCopyToClipboard}
				onClose={() => setShowConfirmationModalCopyToClipboard(false)}
				showButtonClose={true}
				testId={'copy-to-clipboard-modal'}
				appId={true}
				textToClipboard={applicationId.current}
			/>
			<AppIdCopyToClipboardModal
				open={showConfirmationModalCopyS3}
				onClose={() => setShowConfirmationModalCopyS3(false)}
				showButtonClose={true}
				testId={'copy-to-clipboard-modal'}
				appId={false}
				textToClipboard={s3PathwayRef.current}
			/>
			{displayFilesDownloadModal ? (
				<FilesDownloadModal
					displayFilesDownloadModal={displayFilesDownloadModal}
					isApiBased={isApiBased}
					setDisplayFilesDownloadModal={setDisplayFilesDownloadModal}
					onDownloadButtonClick={getFiles}
					downloadableFiles={isApiBased ? downloadableFilesAPI : downloadableFiles}
					testId={testId + '-fdm'}
					salesforceSource={sourceIntegrationType === 'Salesforce'}
					showNotice={showNotice}
					enrichOnly={enrichOnly}
				/>
			) : null}
			<div
				className={styles.uploadTileContainer}
				onClick={() => {
					if (!displayedSection) onClickSelection()
				}}
				data-testid={testId + '-upload-tile-container'}
			>
				<div className={styles.uploadTileHoverPinkBord} />
				{isLoading || isDownloading ? (
					<LoadingState
						loadingMessage={
							isDownloading ? (t('upload.title.loading.container.loading.message') as string) : undefined
						}
						testId="loading-download-files"
					/>
				) : (
					<>
						{errorMessage && (
							<ErrorState
								retryText={t('upload.tile.loading.container.error.button.text') as string}
								errorMessage={errorMessage}
								onRetry={() => setErrorMessage(undefined)}
								onClose={() => setErrorMessage(undefined)}
								testId={testId + '-err-state'}
							/>
						)}
						<div data-testid="upload tile-content" className={styles.uploadTileContent}>
							<div
								data-testid="upload-tile-header-container"
								className={styles.uploadTileHeaderContainer}
							>
								<>
									{(status === 'ERROR' || errorMessageUpload !== '') && isConnectManage && (
										<ToolTip
											customContent={
												<div
													className={styles.warningTooltip}
													style={{ fontSize: SizingFontMedium }}
												>
													{t('upload.tile.restart.tooltip')}
												</div>
											}
											testId={'tooltip-restart-tooltip'}
										>
											<div className={styles.warningConnectManage}>
												<Icon
													testId="alert-big-upload-connect-manage"
													type="emptywarning"
													size="mini"
													color={ColorOrangeBurnt}
												/>
											</div>
										</ToolTip>
									)}
									<h1
										data-testid="upload-tile-company-name"
										className={styles.uploadTileCompanyName}
										title={displayName}
									>
										{displayName ? removeFileNameExtension(displayName) : ''}
									</h1>
									{relationVersions !== undefined && versionsUpload.length > 0 && (
										<div>
											<ContextMenu
												options={versionsUpload
													.map((version, idx) => {
														const totalVersions = versionsUpload.length
															? versionsUpload.length + 1
															: 0
														return {
															label: `V${totalVersions - idx} ${
																version.uploadId === selectedVersion?.uploadId
																	? t('list.versions.upload.text.current')
																	: ''
															}`,
															action: version.uploadId
														}
													})
													.concat([
														{
															label: `${t('list.versions.original.input')} ${
																originalVersion?.uploadId === selectedVersion?.uploadId
																	? t('list.versions.upload.text.current')
																	: ''
															}`,
															action: originalVersion?.uploadId || ''
														}
													])}
												testId={'list-versions-upload'}
												onMenuClicked={(option) => {
													if (onClickVersion) {
														const val =
															versionsUpload.filter((v) => v.uploadId === option) || []
														if (val.length > 0) {
															onClickVersion(val[0])
														} else {
															onClickVersion(originalVersion as Upload)
														}
													}
												}}
												alignment={'left'}
												withIndicator={uploadId}
											>
												<Icon type={'arrow-down'} size={'mini'} />
											</ContextMenu>
										</div>
									)}
								</>
								<div
									data-testid="upload-tile-context-menu-container"
									className={styles.uploadTileContextMenuContainer}
								>
									{type === UploadType.ENRICH_ONLY && (
										<div className={styles.uploadPillContainer}>
											<Pill text={t('enrich.only')} />
										</div>
									)}
									<ShowHideSectionIcon
										displayedSection={displayedSection}
										onClickSectionIcon={onClickSelection}
										testId={testId + '-shsi'}
									/>
									{(status === 'ERROR' || errorMessageUpload !== '') && !isConnectManage && (
										<Icon
											testId="alert-big-upload-tile"
											type="alert-big"
											size="mini"
											color={ColorOrangeBurnt}
										/>
									)}
									{showContextMenu && (
										<ContextMenu
											options={contextMenuOptions || []}
											onMenuClicked={onMenuClicked}
											testId={testId + '-menu'}
										/>
									)}
								</div>
							</div>
							<UploadTileStats stats={stats} testId="project-detail" />
							{!isApiBased || (isApiBased && EnableTxnApiErrorReporting && existsTXNErrors) ? (
								<>
									<div className={styles.uploadTileProgress}>
										{displayDownloadButton ? (
											<div className={styles.uploadButtonNSpinner}>
												{downloadableFiles && (
													<Button
														text={t('upload.tile.button.text')}
														onClick={() => setDisplayFilesDownloadModal(true)}
														short
														colors={buttonColors}
														testId={testId + '-upload-tile-button'}
														isDisabled={downloadDisabled}
														slideEffect={true}
													/>
												)}
												{showUpdateNowButton ? (
													<>
														{stewardshipDisabled ? (
															<div className={styles.stewardshipDisabled}>
																{t('stewardship.disabled')}
															</div>
														) : (
															<Button
																text={t('stewardship.update.now')}
																onClick={() => onClickUpdateNow && onClickUpdateNow()}
																short
																colors={buttonColors}
																testId={testId + '-finalize-button'}
															/>
														)}
													</>
												) : undefined}
												{showRefreshButton && (
													<DNBTooltip
														arrow
														content={
															dataDeleted
																? t('upload.tile.button.refresh.tooltip')
																: refreshDisabled
																? t('tooltip.refresh.button.currently.refreshing')
																: null
														}
														placement="top"
													>
														<div className={styles.uploadButtonRefreshing}>
															<Button
																text={
																	refreshDisabled &&
																	relationVersions?.versions !== undefined &&
																	relationVersions?.versions[0].status !== 'ERROR' &&
																	relationVersions?.versions[0].status !==
																		'FINISHED' &&
																	upload.status !== 'CLEANED_UP'
																		? t('button.upload.refresh.requested')
																		: uploadId ===
																		  relationVersions?.original.uploadId
																		? t('upload.tile.button.refresh')
																		: t('upload.tile.button.refresh.original')
																}
																onClick={() => {
																	if (onClickRefresh) {
																		setRefreshDisabled(true)
																		if (relationVersions)
																			onClickRefresh(
																				upload,
																				relationVersions?.original
																			)
																		else onClickRefresh(upload)
																	}
																}}
																short
																colors={secondaryButtonColors}
																testId={testId + '-refresh-data-button'}
																isDisabled={refreshDisabled}
																type="secondary"
																slideEffect={true}
															/>
														</div>
													</DNBTooltip>
												)}
											</div>
										) : (
											<ProgressBar
												svgWidth={153}
												svgHeight={14}
												rounded={'oneSide'}
												id={`progress-bar-${uploadId.replace(/\s/g, '')}`}
												value={statusPercentage}
												total={100}
												displayPercentage
												numberToShow="none"
												colors={{
													barColor: progressBarColor,
													containerColor: progressColor
												}}
											/>
										)}
										{statusMessage && (
											<p className={styles.uploadTileStatusText} style={{ color: statusColor }}>
												{t(`upload.tile.status.${statusMessage}`)}
											</p>
										)}
									</div>
									<div className={styles.uploadTileDatesContainer}>
										{createdDateTimestamp ? (
											<div className={styles.dateTextContainer}>
												{t('text.created') + ' ' + createdDate.toLocaleDateString()}
											</div>
										) : undefined}
										{displayExpirationDate && (
											<DNBTooltip
												arrow
												content={
													downloadDisabled
														? t('upload.tile.button.download.disabled.tooltip')
														: null
												}
												placement="top"
											>
												<div
													className={cx(
														styles.dateTextContainer,
														`${(isDateSoonToExpire || dataExpired) && styles.expiredText}`
													)}
												>
													{(isDateSoonToExpire || dataExpired) && <WarningAmberIcon />}
													{`${
														dataExpired
															? t('upload.tile.label.expired')
															: t('upload.tile.label.expires')
													} ${expirationDate.toLocaleDateString()}`}
												</div>
											</DNBTooltip>
										)}
									</div>
									{!isApiBased && upload.isFileLimitReached && (
										<div className={styles.uploadTextFileLimit}>
											{t('upload.tile.file.limit.imposed')}
										</div>
									)}
								</>
							) : undefined}
						</div>
					</>
				)}
				{showCopiedMessage && <div className={styles.copiedClipboardMessage}>{t('copied.to.clipboard')}</div>}
			</div>
		</>
	)
}
