import { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { CountryMapSummary, IRegionCustom } from '../../../../local-core-ui'
import world from '../../../../local-core-ui/assets/data/world.json'
import { RootState, useAppSelector } from '../../../../store'
import { BASE_RULE, MatchRuleInfo } from '../../../../store/projectWizard/types'
import { MatchStyle } from '../../../../types'
import { ruleColorList } from '../../color-list'
import { getMatchGradePatterns } from '../utils'
import { getInclusionCriteria } from './getInclusionCriteria'
import { MatchRecommendationSummarySection } from './match-recommendation/match-recommendation-summary-section'
import { MatchSummaryInfoRule } from './match-summary-info-rule'
import styles from './match-summary.module.scss'

export function MatchSummary(): ReactElement {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const rules = [...projectWizardState.currentProject.matchRules]
	const containerRules = useRef<HTMLHeadingElement>(null)
	const enableNonmarketableInclusion = useFeatures(['EnableNonmarketableInclusion'])
	const orderBaseRule = (): MatchRuleInfo[] => {
		const baseRuleIndex = rules.findIndex((matchRuleInfo) => matchRuleInfo.matchRule.ruleType === BASE_RULE)
		const newRules = [...rules]
		const baseRule = newRules.splice(baseRuleIndex, 1)
		return [...baseRule, ...newRules]
	}
	const sortedRules = orderBaseRule()

	const getCountriesBaseRule = () => {
		const countriesBaseRule: Array<string> = []
		let countriesOthersRules: Array<string> = []
		rules.forEach((rule: MatchRuleInfo) => {
			if (rule.matchRule.allowedValues) {
				countriesOthersRules = countriesOthersRules.concat(rule.matchRule.allowedValues)
			}
		})
		world.features.forEach((country) => {
			if (countriesOthersRules.find((countryRule) => countryRule === country.id2) === undefined) {
				countriesBaseRule.push(country.id2)
			}
		})
		return countriesBaseRule
	}

	const getCountrySummary = () => {
		const countrySummary: IRegionCustom[] = []
		sortedRules.forEach((rule, idx) => {
			const colorIdx = idx % ruleColorList.length
			const color = ruleColorList[colorIdx]
			let values = rule.matchRule.allowedValues || []
			if (rule.matchRule.ruleType === BASE_RULE) {
				values = getCountriesBaseRule()
			}
			countrySummary.push({
				blockId: rule.matchRule.matchRuleId || '',
				values: values,
				blockName: rule.matchRule.displayName || '',
				color: color
			})
		})
		return countrySummary
	}

	const getGeographicalDistribution = (idx: number) => {
		if (sortedRules[idx].matchRule.ruleType === BASE_RULE) {
			return 'All Countries'
		} else {
			const countries: Array<string> = []
			sortedRules[idx].matchRule?.allowedValues?.map((allowedValue) => {
				const countryWorld = world.features.find((country) => country.id2 === allowedValue)
				if (countryWorld) countries.push(countryWorld.properties.name)
			})
			return countries.join(', ')
		}
	}

	const getExcludeRules = (idx: number) => {
		if (sortedRules[idx].matchRule.ruleType === BASE_RULE) {
			const excludeSorter: Array<{ nameRule: string; color: string }> = []
			sortedRules.forEach((rule, index) => {
				const colorIdx = index % ruleColorList.length
				const color = ruleColorList[colorIdx]
				if (index !== idx) {
					excludeSorter.push({ nameRule: rule.matchRule.displayName || '', color: color })
				}
			})
			return excludeSorter
		} else {
			return undefined
		}
	}

	const getMatch = (rule: MatchRuleInfo) => {
		const patterns = getMatchGradePatterns(rule)
		return { matchType: rule.matchQualityType, matching: patterns.join(' , ') }
	}

	const getInclusionCriteriaTranslations = (rule: MatchRuleInfo) => {
		return getInclusionCriteria(rule, enableNonmarketableInclusion)
			?.map((includeKey) => {
				return t(includeKey)
			})
			?.join(', ')
	}

	return (
		<div className={styles.matchSummaryStep}>
			<h1 data-testid="matching-summary-step">{t('matching.step.summary.title')}</h1>
			<span className={styles.description}>{t('matching.step.summary.description')}</span>
			{sortedRules.length > 0 && sortedRules[0].matchRule.matchStyle === MatchStyle.MATCH_RECOMMENDATION ? (
				<MatchRecommendationSummarySection matchRuleInfo={sortedRules[0]} />
			) : (
				rules && (
					<>
						<div className={styles.containerMap} key={'rule-summary'}>
							<CountryMapSummary
								width={513}
								countrySummary={getCountrySummary()}
								toggleCountry={(group: IRegionCustom) => {
									const positionRule = sortedRules.findIndex(
										(rule) => rule.matchRule.matchRuleId === group.blockId
									)
									if (containerRules?.current) {
										const elements = document.getElementsByClassName('match-summary-info-rule')
										let positionY = 0
										for (let i = 0; i < positionRule; i++) {
											positionY = positionY + elements[i].clientHeight + 24
										}
										containerRules?.current?.scrollTo(0, positionY)
									}
								}}
								testId={`match-summary-map`}
							/>
						</div>

						<div className={styles.containerRules} id={'container-rules'} ref={containerRules}>
							{sortedRules.map((rule, idx: number) => {
								const colorIdx = idx % ruleColorList.length
								const color = ruleColorList[colorIdx]
								return (
									<div key={`info-rule-${rule.matchRule.displayName}`}>
										<MatchSummaryInfoRule
											nameRule={rule.matchRule.displayName || ''}
											confidenceCode={rule.matchRule.acceptCriterion.LowestConfidenceCode || 0}
											geographicalDistribution={getGeographicalDistribution(idx)}
											match={getMatch(rule)}
											inclusionCriteria={getInclusionCriteriaTranslations(rule)}
											color={color}
											excludeRules={getExcludeRules(idx)}
											testId={`info-rule-${rule.matchRule.displayName}`}
										/>
									</div>
								)
							})}
						</div>
					</>
				)
			)}
		</div>
	)
}
