import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { convertTo3CountryCode } from '../../helpers'
import { useFeatures } from '../../hooks/useEntitlements'
import { Button, CountrySelectWithMap, ToolTip } from '../../local-core-ui'
import { MatchRule } from '../../types'
import styles from './match-rule-summary-tile.module.scss'

interface IMatchRule {
	matchSummary: MatchRule
	readOnly?: boolean
	testId: string
	color?: string
}

const inclusionCriteriaKeys = ['NonHeadQuarters', 'OutOfBusiness', 'Unreachable', 'NonMarketable', 'Undeliverable']
const arrayDifference = (enableNonmarketableInclusion: boolean, array1: string[], array2?: string[]) => {
	// returns array1 - array2
	let difference: string[] = array1
	if (array2) {
		difference = array1.filter((key: string) => {
			if (key === 'NonMarketable' && !enableNonmarketableInclusion) {
				return false
			}
			return !array2.includes(key)
		})
	}
	return difference
}

export const MatchRuleSummaryTile: FC<IMatchRule> = ({ matchSummary, readOnly = false, testId, color }: IMatchRule) => {
	const enableNonmarketableInclusion = useFeatures(['EnableNonmarketableInclusion'])

	const { t } = useTranslation()
	let include = arrayDifference(enableNonmarketableInclusion, inclusionCriteriaKeys, matchSummary?.exclusionCriterion)
		?.map((includeKey) => {
			return t(`match.rule.summary.tile.${includeKey}`)
		})
		?.join(',')
	const [displaySource, setDisplaySource] = useState(false)
	let matchGradePatterns = matchSummary?.acceptCriterion?.MatchGradePatterns?.join(', ')

	include = include ? include : t('match.rule.summary.tile.inclusion.empty')
	matchGradePatterns = matchGradePatterns ? matchGradePatterns : t('match.rule.summary.tile.match.string.code.empty')

	return (
		<div
			className={styles.matchRuleSummaryTileContainer}
			onMouseOver={() => setDisplaySource(true)}
			onMouseLeave={() => setDisplaySource(false)}
			data-testid={testId}
		>
			{!readOnly && displaySource ? (
				<div className={styles.matchRuleSummarySourceContainer}>
					<div className={styles.matchRuleSummaryTilePinkBord} />
					<p className={styles.matchRuleSummarySourceName}>{matchSummary.sourceId}</p>
					<Button
						text={t('match.rule.summary.tile.use.rule')}
						short
						onClick={() => console.log('button-click')}
						testId={testId + '-use-rule'}
					/>
				</div>
			) : (
				<>
					<CountrySelectWithMap
						readOnly
						width={160}
						value={
							matchSummary.ruleType === 'BASE_RULE'
								? 'All'
								: convertTo3CountryCode(matchSummary.allowedValues)
						}
						translationFunction={t}
						testId={testId + '-cswm'}
						color={color}
					/>
					<div
						data-testid="match-rule-summary-tile-name-container"
						className={styles.matchRuleSummaryTileNameContainer}
					>
						<p className={styles.matchRuleSummaryTileData}>{t('match.rule.summary.tile.name')}</p>
						<p data-testid="match-rule-summary-tile-name" className={styles.matchRuleSummaryTileName}>
							{matchSummary.displayName}
						</p>
					</div>
					<p className={styles.matchRuleSummaryTileData}>
						{t('match.rule.summary.tile.confidence.code')}
						<span data-testid="confidence-code-summary">
							{matchSummary?.acceptCriterion?.LowestConfidenceCode}
						</span>
					</p>
					<ToolTip
						text={matchGradePatterns}
						position="right"
						effect="float"
						testId={testId + '-tt-match-grade-patterns'}
					>
						<p className={styles.matchRuleSummaryTileData}>
							{t('match.rule.summary.tile.match.string.code')}
							<span data-testid="match-grade-patterns-summary">{matchGradePatterns}</span>
						</p>
					</ToolTip>
					<ToolTip text={include} effect="float" position="right" testId={testId + '-tt-include'}>
						<p className={styles.matchRuleSummaryTileData}>
							{t('match.rule.summary.tile.inclusion')}
							<span data-testid="inclusion-summary">{include}</span>
						</p>
					</ToolTip>
				</>
			)}
		</div>
	)
}
