import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWizardContext } from '../../components/wizardComposer/hooks/useWizardContext'
import { filterDeprecatedElements, filterElementsByEntitlements } from '../../helpers'
import { useApi } from '../../hooks'
import { LoadingState } from '../../local-core-ui'
import { isEnableEmailVerification } from '../../project/steps/mapping-v2/WizardComposerMappingStepDefinition'
import { useDataBlocksEntitlements } from '../../queries/useDataBlocksEntitlements'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { outboundTransform } from '../../store/projectWizard/transformer'
import { ElementDetail } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import styles from './dashboard-myDataBlocks.module.scss'
import { MemDataBlockResultsPanel } from './DataBlockResultsPanel'
import { DataBlocksFilterPanel } from './DataBlocksFilterPanel'

export interface levelObj {
	[key: string]: boolean
}

export interface dataCategory {
	[key: string]: levelObj
}

export interface recordType {
	domain: string
	recordType: string
}

export const ViewMyDataBlocks = (): ReactElement => {
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)

	const { t } = useTranslation()
	const { wizardContext } = useWizardContext()
	const testId = 'my-datablock'
	const apiClient = useApi()
	const entitlementQuery = useDataBlocksEntitlements()
	const dispatch = useAppDispatch()
	const [isLoading, setisLoading] = useState<boolean>(true)
	const [blockList, setBlockList] = useState<Array<ElementDetail>>([])
	const [filterPanelRendered, setFilterPanelRendered] = useState(0)
	const [dataBlocksFilterArray, setdataBlocksFilterArray] = useState<dataCategory>({})
	const [domains, setDomains] = useState<Array<string>>([])
	const [selectedDomain, setSelectedDomain] = useState<string>('')
	const entityType: EntityType = EntityType.CONTACTS
	const [purposeOfUse, setPurposeOfUse] = useState<Array<recordType>>([])
	const [tenantId, setTenantId] = useState(session.tenant)
	const [showNoEntitlementMessage, setShowNoEntitlementMessage] = useState<boolean>(false)

	const getBlockList = async () => {
		const domainIdx = domains.indexOf(selectedDomain)
		const selectedElements: Array<string> = []

		const dataBlockList = await filterElementsByEntitlements(
			apiClient,
			dispatch,
			selectedElements,
			purposeOfUse[domainIdx],
			undefined,
			entityType
		)
		setBlockList(
			filterDeprecatedElements(dataBlockList, selectedElements, isEnableEmailVerification(wizardContext))
		)
	}

	const createDataCategoryFilterObject = () => {
		const dataBlocksFilterArray: dataCategory = {}
		blockList.forEach((block) => {
			const levels = block.levels ? block.levels : []
			const levelsObj: levelObj = {}
			levels.forEach((level) => {
				levelsObj[level?.level?.levelId] = true
			})
			if (dataBlocksFilterArray[block.blockId as keyof typeof dataBlocksFilterArray]) {
				dataBlocksFilterArray[block.blockId] = levelsObj
			} else {
				dataBlocksFilterArray[block.blockId] = {}
				dataBlocksFilterArray[block.blockId] = levelsObj
			}
		})
		setdataBlocksFilterArray(dataBlocksFilterArray)
	}

	const onChangeDataType = (domainidx: number) => {
		setSelectedDomain(domains[domainidx])
	}
	const updateFilter = (blockId: string, levelId: string, isChecked: boolean, selectAll: boolean): void => {
		const tempObj = Object.assign({}, dataBlocksFilterArray)
		const selectedLevelObj = tempObj[blockId as keyof typeof tempObj]
		if (levelId) {
			selectedLevelObj[levelId as keyof typeof selectedLevelObj] =
				!selectedLevelObj[levelId as keyof typeof selectedLevelObj]
			tempObj[blockId as keyof typeof tempObj] = selectedLevelObj
		} else if (blockId) {
			Object.keys(selectedLevelObj).forEach((key) => {
				selectedLevelObj[key as keyof typeof selectedLevelObj] = isChecked
			})
			tempObj[blockId as keyof typeof tempObj] = selectedLevelObj
		} else {
			Object.keys(tempObj).forEach((key) => {
				const selectedLevelObject = tempObj[key as keyof typeof tempObj]
				Object.keys(selectedLevelObject).forEach((key) => {
					selectedLevelObject[key as keyof typeof selectedLevelObject] = selectAll
				})
				tempObj[key as keyof typeof tempObj] = selectedLevelObject
			})
		}
		setdataBlocksFilterArray(tempObj)
	}

	useEffect(() => {
		const currentTenant = JSON.parse(window.localStorage.getItem('currentTenant'))
		const currentTenantId = outboundTransform(currentTenant, 'local')
		if (entitlementQuery.isSuccess && entitlementQuery?.data) {
			const initialDomains: Array<string> = []
			const purposeOfUseAry: Array<recordType> = []

			entitlementQuery.data.forEach((domainObj) => {
				const domainName = domainObj.domain
				const recordTypes = domainObj.recordTypes
				Object.keys(recordTypes).forEach((key) => {
					const datacategory = domainName + ' - ' + key
					initialDomains.push(datacategory)

					const purposeOfUseObj: recordType = {}
					purposeOfUseObj.domain = domainName
					purposeOfUseObj.recordType = key
					purposeOfUseAry.push(purposeOfUseObj)
				})
			})
			setDomains(initialDomains)
			setPurposeOfUse(purposeOfUseAry)
			if (tenantId !== currentTenantId.Identifier) {
				setTenantId(currentTenantId.Identifier)
			}
			if (selectedDomain === '' || tenantId !== currentTenantId.Identifier) setSelectedDomain(initialDomains[0])
			if (purposeOfUse.length > 0 && tenantId === currentTenantId.Identifier) {
				purposeOfUseAry.length > 0 && getBlockList().then()
			}
		} else if (!entitlementQuery.isFetching) {
			setShowNoEntitlementMessage(true)
		}
	}, [selectedDomain, entitlementQuery.isFetching, session.tenant])

	useEffect(() => {
		blockList && blockList.length > 0 && createDataCategoryFilterObject()
		setisLoading(false)
	}, [blockList])

	useEffect(() => {
		setFilterPanelRendered(new Date().getTime())
	}, [dataBlocksFilterArray])

	return (
		<div>
			<div data-testid={testId + '-container'} className={styles.panel}>
				{isLoading ? (
					<LoadingState />
				) : (
					<>
						<h1 data-testid={testId + '-title'} className={styles.title}>
							{t('dashboard.myDataBlocks.title')}
						</h1>
						<div className={styles.myDataBlockCont}>
							{dataBlocksFilterArray && blockList && blockList.length ? (
								<DataBlocksFilterPanel
									blockList={blockList}
									dataBlocksFilterArray={dataBlocksFilterArray}
									updateFilter={updateFilter}
									testId={testId}
									selectedDomain={selectedDomain}
									onChangeDataType={onChangeDataType}
									domains={domains}
								/>
							) : (
								<></>
							)}
							{filterPanelRendered ? (
								<div className={styles.dataBlockContainer}>
									<MemDataBlockResultsPanel
										blockList={blockList}
										dataBlocksFilterArray={dataBlocksFilterArray}
										testId={testId}
										filterApplied={filterPanelRendered}
										showNoEntitlementMessage={showNoEntitlementMessage}
									/>
								</div>
							) : (
								<></>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
