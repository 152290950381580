import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColorGray, ColorGrayDark } from '../../../../design-tokens/build/shared/token-colors.json'
import { Button, Divider, Modal } from '../../local-core-ui/'
import { ConnectionStatus as StatusType } from '../../queries/api/getC4SActiveConnection'
import { ConnectionStatus } from '../connection-status/connection-status'
import styles from './connection-status-info-modal.module.scss'

interface ConnectionStatusInfoModalProps {
	open: boolean
	onClose(): void
	testId: string
}

export const ConnectionStatusInfoModal = ({ open, onClose, testId }: ConnectionStatusInfoModalProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<Modal open={open} onClose={() => onClose()} testId={testId} showButtonClose={true} fixWidth={false}>
			<div className={styles.mainContainer}>
				<div className={styles.title}>{t('connection.status.info.title')}</div>
				<div className={styles.description}>{t('connection.status.info.description')}</div>
				<div className={styles.tableTitle}>{t('connection.status.info.table.salesforce.header')}</div>
				<section className={styles.tableContainer}>
					<div className={styles.divider} />
					<div className={styles.tableHeader}>
						<div className={`${styles.statusCol} ${styles.col}`}>
							{t('connection.status.info.table.status')}
						</div>
						<div className={`${styles.detailsCol} ${styles.col}`}>
							{t('connection.status.info.table.details')}
						</div>
					</div>
					<div className={styles.dividerG} />
					<div className={styles.row}>
						<div className={`${styles.statusCol} ${styles.col}`}>
							<ConnectionStatus connectionStatus={StatusType.Ready} isStatusLoading={false} />
						</div>
						<div className={`${styles.detailsCol} ${styles.col}`}>
							{t('connection.status.info.table.details.ready')}
						</div>
					</div>
					<Divider color={ColorGrayDark} />
					<div className={styles.row}>
						<div className={`${styles.statusCol} ${styles.col}`}>
							<ConnectionStatus connectionStatus={StatusType.Unknown} isStatusLoading={false} />
						</div>
						<div className={`${styles.detailsCol} ${styles.col}`}>
							<Trans i18nKey="connection.status.info.table.details.unknown" />
						</div>
					</div>
					<Divider color={ColorGrayDark} />
					<div className={styles.row}>
						<div className={`${styles.statusCol} ${styles.col}`}>
							<ConnectionStatus connectionStatus={StatusType.Failed} isStatusLoading={false} />
						</div>
						<div className={`${styles.detailsCol} ${styles.col}`}>
							<Trans i18nKey="connection.status.info.table.details.failed" />
						</div>
					</div>
					<div className={styles.divider} />
				</section>
			</div>
			<div className={`${styles.buttonContainer} ${''}`}>
				<Divider color={ColorGray} />
				<div className={styles.buttons}>
					<div className={styles.continueButton}>
						<Button
							size={'mini'}
							type={'primary'}
							text={t('Close')}
							onClick={() => onClose()}
							testId={testId + '-close'}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}
