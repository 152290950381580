import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../../design-tokens/build/shared/token-colors.json'
import { getLocalizedDateString } from '../../helpers'
import { useApi } from '../../hooks'
import { Divider } from '../../local-core-ui'
import { ConnectionDetail, getConnection } from '../../queries/api/getConnection'
import { Connection, System } from '../../queries/api/getConnections'
import { RootState, useAppSelector } from '../../store'
import { ConnectionStatus } from '../connection-status/connection-status'
import { ConnectionTile } from '../connection-tile/connection-tile'
import { NewConnectionTile } from '../new-connection-tile/new-connection-tile'
import { SnowflakeModal } from '../snowflake-modal/snowflake-modal'
import { Spinner } from '../spinner/spinner'
import styles from './connections-view.module.scss'

export interface ConnectionTableProps {
	connections?: Connection[]
	isTileView: boolean
	onClickNewConnection(): void
	onEditConnection(connectionDetail: ConnectionDetail): void
	isLoading: boolean
	isConnectionStatusLoading: boolean
	firstListItem: number
	lastListItem: number
}

export const ConnectionsView = ({
	connections,
	isTileView,
	onClickNewConnection,
	onEditConnection,
	isLoading,
	isConnectionStatusLoading,
	firstListItem,
	lastListItem
}: ConnectionTableProps): ReactElement => {
	const { t } = useTranslation()
	const [showDetailModal, setShowDetailModal] = useState(false)
	const [connectionDetail, setConnectionDetail] = useState<ConnectionDetail>()
	const [connectionName, setConnectionName] = useState('')
	const apiClient = useApi()
	const [isRoleAllowed, setIsRoleAllowed] = useState(false)

	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const loggedUserRole = session.user?.AccessLevel

	const allowedRoles = ['EXTERNAL_ADMIN']

	const onClickSeeConnection = (connection: string) => {
		getConnection(apiClient, connection).then((response) => {
			//If we have the connection status, pass it along to the Edit tile
			if (!isConnectionStatusLoading && connections) {
				response.status = connections.find((con) => con.name === response.name)?.status
			}

			if (response.system === System.Salesforce || response.system === System.ConnectManage) {
				onEditConnection(response)
			} else if (response.system === System.Snowflake) {
				setConnectionName(response.displayName || '')
				setConnectionDetail(response)
				setShowDetailModal(true)
			}
		})
	}

	useEffect(() => {
		setIsRoleAllowed(allowedRoles.includes(loggedUserRole))
	}, [loggedUserRole])

	return (
		<div className={styles.connectionsContainer}>
			{isLoading && (
				<div className={styles.loadingSpinner}>
					<Spinner />
				</div>
			)}
			{showDetailModal && connectionDetail && (
				<div className={styles.snowModal}>
					<SnowflakeModal
						open={showDetailModal}
						onCloseModal={() => setShowDetailModal(false)}
						title={connectionName}
						continueText={t('connections.edit')}
						onContinue={() => onEditConnection(connectionDetail)}
						isContinueDisable={false}
						dataToFill={connectionDetail}
						testId={'see-connection'}
						showPassword={false}
					/>
				</div>
			)}
			{isTileView ? (
				<>
					{connections?.slice(firstListItem, lastListItem).map((connection, key) => (
						<ConnectionTile
							key={`${connection.name}-${key}`}
							connection={connection}
							onClickSeeConnection={onClickSeeConnection}
							isStatusLoading={isConnectionStatusLoading}
						/>
					))}
					{isRoleAllowed && !isLoading && lastListItem >= connections?.length && (
						<NewConnectionTile onClickTile={() => onClickNewConnection()} />
					)}
				</>
			) : (
				<section className={styles.tableContainer}>
					<div className={styles.divider} />
					<div className={styles.tableHeader}>
						<div className={`${styles.nameCol} ${styles.col}`}>{t('dashboard.connections.sort.name')}</div>
						<div className={`${styles.connectorCol} ${styles.col}`}>{t('connection.connector')}</div>
						<div className={`${styles.statusCol} ${styles.col}`}>{t('company.detail.tile.status')}</div>
						<div className={`${styles.dateCol} ${styles.col}`}>{t('connection.updated.date')}</div>
						<div className={`${styles.dateCol} ${styles.col}`}>{t('connection.created.date')}</div>
						<div className={`${styles.createdByCol} ${styles.col}`}>{t('connection.created.by')}</div>
					</div>
					{connections?.slice(firstListItem, lastListItem).map((connection, key) => (
						<div key={`${connection.name}-${key}`}>
							{key === 0 ? <div className={styles.dividerG} /> : <Divider color={ColorGrayDark} />}

							<div className={styles.row} onClick={() => onClickSeeConnection(connection.name)}>
								<div className={`${styles.nameCol} ${styles.col}`}>{connection.displayName}</div>
								<div className={`${styles.connectorCol} ${styles.col}`}>{connection.system}</div>
								<div className={`${styles.statusCol} ${styles.col}`}>
									<ConnectionStatus
										connectionStatus={connection.status}
										isStatusLoading={isConnectionStatusLoading}
									/>
								</div>
								<div className={`${styles.dateCol} ${styles.col}`}>
									{getLocalizedDateString(connection.updated)}
								</div>
								<div className={`${styles.dateCol} ${styles.col}`}>
									{getLocalizedDateString(connection.created)}
								</div>
								<div className={`${styles.createdByCol} ${styles.col}`}>{connection.user}</div>
							</div>
						</div>
					))}
					<div className={styles.divider} />
				</section>
			)}
		</div>
	)
}
